import * as actionTypes from './actionTypes';
import {OrderActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Order, ShipmentPrice} from '../../domain/Order';

export type OrderStateType = {
  checkPriceLoading: boolean,
  checkPriceError: HttpError,
  shipmentPrices: ShipmentPrice[],
  createOrderLoading: boolean,
  createOrderError: HttpError,
  createOrderSuccess: boolean,
  getOrdersLoading: boolean,
  getOrdersError: HttpError,
  orders: Order[],
  getCartLoading: boolean,
  getCartError: HttpError,
  cart: Order[],
  deleteOrderLoading: boolean,
  deleteOrderError: HttpError,
  deleteOrderSuccess: boolean,
  selectedOrder: Order | null,
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes,
};

export const initialState: OrderStateType = {
  checkPriceLoading: false,
  shipmentPrices: [],
  checkPriceError: null,
  createOrderLoading: false,
  createOrderError: null,
  createOrderSuccess: false,
  getOrdersLoading: false,
  getOrdersError: null,
  orders: [],
  getCartLoading: false,
  getCartError: null,
  cart: [],
  deleteOrderLoading: false,
  deleteOrderError: null,
  deleteOrderSuccess: false,
  selectedOrder: null,
};

const checkPriceStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  checkPriceLoading: true,
});

const checkPriceSuccess = (state: OrderStateType, action: OrderActionType): OrderStateType => ({
  ...state,
  checkPriceLoading: false,
  checkPriceError: null,
  shipmentPrices: action.shipmentPrices,
  createOrderSuccess: false,
});

const checkPriceFail = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  checkPriceError: action.checkPriceError,
  checkPriceLoading: false,
});

const createOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  createOrderLoading: true,
});

const createOrderSuccess = (state: OrderStateType, action: OrderActionType): OrderStateType => ({
  ...state,
  createOrderLoading: false,
  createOrderError: null,
  createOrderSuccess: true,
  selectedOrder: action.selectedOrder,
});

const createOrderFail = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  createOrderError: action.createOrderError,
  createOrderLoading: false,
});

const getOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  getOrdersLoading: true,
});

const getOrdersSuccess = (state: OrderStateType, action: OrderActionType): OrderStateType => ({
  ...state,
  getOrdersLoading: false,
  getOrdersError: null,
  orders: action.orders,
});

const getOrdersFail = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  getOrdersError: action.getOrdersError,
  getOrdersLoading: false,
});

const getCartStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  getCartLoading: true,
});

const getCartSuccess = (state: OrderStateType, action: OrderActionType): OrderStateType => ({
  ...state,
  getCartLoading: false,
  getCartError: null,
  cart: action.cart,
});

const getCartFail = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  getCartError: action.getCartError,
  getCartLoading: false,
});

const deleteOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  deleteOrderLoading: true,
});

const deleteOrderSuccess = (state: OrderStateType): OrderStateType => ({
  ...state,
  deleteOrderLoading: false,
  deleteOrderError: null,
  deleteOrderSuccess: true,
});

const deleteOrderFail = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  deleteOrderError: action.deleteOrderError,
  deleteOrderLoading: false,
});

const selectOrder = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  selectedOrder: action.selectedOrder,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
  case actionTypes.CHECK_PRICE_START:
    return checkPriceStart(state);
  case actionTypes.CHECK_PRICE_SUCCESS:
    return checkPriceSuccess(state, action);
  case actionTypes.CHECK_PRICE_FAIL:
    return checkPriceFail(state, action);
  case actionTypes.CREATE_ORDER_START:
    return createOrderStart(state);
  case actionTypes.CREATE_ORDER_SUCCESS:
    return createOrderSuccess(state, action);
  case actionTypes.CREATE_ORDER_FAIL:
    return createOrderFail(state, action);
  case actionTypes.GET_ORDERS_START:
    return getOrdersStart(state);
  case actionTypes.GET_ORDERS_SUCCESS:
    return getOrdersSuccess(state, action);
  case actionTypes.GET_ORDERS_FAIL:
    return getOrdersFail(state, action);
  case actionTypes.GET_CART_START:
    return getCartStart(state);
  case actionTypes.GET_CART_SUCCESS:
    return getCartSuccess(state, action);
  case actionTypes.GET_CART_FAIL:
    return getCartFail(state, action);
  case actionTypes.DELETE_ORDER_START:
    return deleteOrderStart(state);
  case actionTypes.DELETE_ORDER_SUCCESS:
    return deleteOrderSuccess(state);
  case actionTypes.DELETE_ORDER_FAIL:
    return deleteOrderFail(state, action);
  case actionTypes.SELECT_ORDER:
    return selectOrder(state, action);
  default:
    return state;
  }
};

export default reducer;
