import * as actionTypes from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Country} from '../../domain/Country';

export const getCountriesStart = () => ({
  type: actionTypes.GET_COUNTRIES_START,
});

export const getCountriesSuccess = (countries: Country[]) => ({
  type: actionTypes.GET_COUNTRIES_SUCCESS,
  countries,
});

export const getCountriesFail = (getCountriesError: HttpError) => ({
  type: actionTypes.GET_COUNTRIES_FAIL,
  getCountriesError,
});
