export const GET_BALANCE_START = 'GET_BALANCE_START';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAIL = 'GET_BALANCE_FAIL';

export const GET_BALANCE_HISTORY_START = 'GET_BALANCE_HISTORY_START';
export const GET_BALANCE_HISTORY_SUCCESS = 'GET_BALANCE_HISTORY_SUCCESS';
export const GET_BALANCE_HISTORY_FAIL = 'GET_BALANCE_HISTORY_FAIL';

export const ADD_BALANCE_START = 'ADD_BALANCE_START';
export const ADD_BALANCE_SUCCESS = 'ADD_BALANCE_SUCCESS';
export const ADD_BALANCE_FAIL = 'ADD_BALANCE_FAIL';

export const USE_BALANCE_START = 'USE_BALANCE_START';
export const USE_BALANCE_SUCCESS = 'USE_BALANCE_SUCCESS';
export const USE_BALANCE_FAIL = 'USE_BALANCE_FAIL';

export type BalanceActionTypes =
  typeof GET_BALANCE_START |
  typeof GET_BALANCE_SUCCESS |
  typeof GET_BALANCE_FAIL |
  typeof GET_BALANCE_HISTORY_START |
  typeof GET_BALANCE_HISTORY_SUCCESS |
  typeof GET_BALANCE_HISTORY_FAIL |
  typeof ADD_BALANCE_START |
  typeof ADD_BALANCE_SUCCESS |
  typeof ADD_BALANCE_FAIL |
  typeof USE_BALANCE_START |
  typeof USE_BALANCE_SUCCESS |
  typeof USE_BALANCE_FAIL
;
