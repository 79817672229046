import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from './Register.module.scss';
import Input from '../../common/Input/Input';
import Modal from '../../common/Modal/Modal';
import Button from '../../common/Button/Button';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {RegisterRequest} from '../../store/auth/service';
import {StoreState} from '../../config/StoreProvider/StoreProvider';
import * as authService from '../../store/auth/service';
import * as countryService from '../../store/country/service';
import {Country} from '../../domain/Country';
import {HttpError} from '../../config/Axios/axios-instance';
import Alert from '../../common/Alert/Alert';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import {translate} from '../../utility/messageTranslator/translate';
import {Language} from '../../domain/Translation';

type Props = {
  onClose: () => void,
  onRegisterSubmit: (inputs: RegisterRequest) => void,
  onLoginClick: () => void,
  onGetCountries: () => void,
  countries: Country[],
  registerSuccess: boolean,
  error: HttpError,
  isLoading: boolean,
  selectedLanguage: Language,
}

export const Register = ({
  onClose,
  onRegisterSubmit,
  onLoginClick,
  onGetCountries,
  countries,
  registerSuccess,
  error,
  isLoading,
  selectedLanguage,
}: Props) => {
  const intl = useIntl();

  const [name, setName] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [companyCode, setCompanyCode] = useState<string>('');
  const [vatNumber, setVatNumber] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [newsletter, setNewsletter] = useState<string>('0');
  const [agreement, setAgreement] = useState<string>('0');
  const [invoiceMode, setInvoiceMode] = useState<string>('day');
  const [countryId, setCountryId] = useState<string>('');
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    onGetCountries();
  }, []);

  useEffect(() => {
    if (registerSuccess && showErrors) {
      onClose();
    }
  }, [registerSuccess]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.name === 'newsletter') {
      setNewsletter(event.target.value === '0' ? '1' : '0');
      return;
    }

    if (event.target.name === 'agreement') {
      setAgreement(event.target.value === '0' ? '1' : '0');
      return;
    }

    event.preventDefault();

    if (event.target.name === 'name') {
      setName(event.target.value);
    }

    if (event.target.name === 'lastname') {
      setLastname(event.target.value);
    }

    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }

    if (event.target.name === 'password') {
      setPassword(event.target.value);
    }

    if (event.target.name === 'passwordRepeat') {
      setPasswordRepeat(event.target.value);
    }

    if (event.target.name === 'company') {
      setCompany(event.target.value);
    }

    if (event.target.name === 'company') {
      setCompany(event.target.value);
    }

    if (event.target.name === 'companyCode') {
      setCompanyCode(event.target.value);
    }

    if (event.target.name === 'vatNumber') {
      setVatNumber(event.target.value);
    }

    if (event.target.name === 'address') {
      setAddress(event.target.value);
    }

    if (event.target.name === 'city') {
      setCity(event.target.value);
    }

    if (event.target.name === 'postalCode') {
      setPostalCode(event.target.value);
    }

    if (event.target.name === 'countryId') {
      setCountryId(event.target.value);
    }

    if (event.target.name === 'invoiceMode') {
      setInvoiceMode(event.target.value);
    }
  };

  const handleSubmit = () => {
    setShowErrors(true);

    if (!name || !lastname || !address || !city ||
      !countryId || !email || !invoiceMode || !password || !postalCode || agreement === '0'
    ) {
      return;
    }

    onRegisterSubmit(_.omitBy({
      email,
      password,
      city,
      address,
      company: !!company ? company : null,
      lastname,
      newsletter,
      name,
      invoice_mode: invoiceMode,
      company_code: !!companyCode ? companyCode : null,
      country_id: countryId,
      postal_code: postalCode,
      vat_number: !!vatNumber ? vatNumber : null,
    }, _.isNil) as RegisterRequest);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit();
  };

  const getError = () => {
    // @ts-ignore
    if (typeof error === 'object' && error && 'email' in error && error?.email?.toString()) {
      return translate(intl, 'VALIDATION.EMAIL', '');
    }

    return '';
  };

  return (
    <Modal onClose={onClose} title={translate(intl, 'REGISTER.TITLE', '')}>
      {getError() && (
        <Alert variant='danger'>
          {translate(intl, 'VALIDATION.EMAIL', '')}
        </Alert>
      )}
      <form onSubmit={handleFormSubmit} className={styles.registerContainer} noValidate>
        <div className={styles.inputColumns}>
          <div className={styles.column}>
            <Input
              onChange={handleChange}
              name='name'
              value={name}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.NAME', '')}
              placeholder={translate(intl, 'REGISTER.NAME', '')}
              error={showErrors && !name ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='lastname'
              value={lastname}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.LAST_NAME', '')}
              placeholder={translate(intl, 'REGISTER.LAST_NAME', '')}
              error={showErrors && !lastname ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='email'
              value={email}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.EMAIL', '')}
              placeholder={translate(intl, 'REGISTER.EMAIL', '')}
              error={showErrors && !email ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='address'
              value={address}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.ADDRESS', '')}
              placeholder={translate(intl, 'REGISTER.ADDRESS', '')}
              error={showErrors && !address ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='city'
              value={city}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.CITY', '')}
              placeholder={translate(intl, 'REGISTER.CITY', '')}
              error={showErrors && !city ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='postalCode'
              value={postalCode}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.POST_CODE', '')}
              placeholder={translate(intl, 'REGISTER.POST_CODE', '')}
              error={showErrors && !postalCode ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='countryId'
              value={countryId}
              type='select'
              className={styles.input}
              label={translate(intl, 'REGISTER.COUNTRY', '')}
              placeholder={translate(intl, 'REGISTER.COUNTRY', '')}
              error={showErrors && !countryId ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
              options={countries.map((country) => ({label: country.name[selectedLanguage], value: country.id}))}
            />
          </div>
          <div className={styles.column}>
            <Input
              onChange={handleChange}
              name='password'
              value={password}
              type='password'
              className={styles.input}
              label={translate(intl, 'REGISTER.PASSWORD', '')}
              placeholder={translate(intl, 'REGISTER.PASSWORD', '')}
              error={showErrors && !password ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
            <Input
              onChange={handleChange}
              name='passwordRepeat'
              value={passwordRepeat}
              type='password'
              className={styles.input}
              label={translate(intl, 'REGISTER.PASSWORD_REPEAT', '')}
              placeholder={translate(intl, 'REGISTER.PASSWORD_REPEAT', '')}
              error={
                showErrors && password !== passwordRepeat ?
                  translate(intl, 'VALIDATION.PASSWORDS_DO_NOT_MATCH', '') :
                  ''
              }
            />
            <Input
              onChange={handleChange}
              name='company'
              value={company}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.COMPANY', '')}
              placeholder={translate(intl, 'REGISTER.COMPANY', '')}
            />
            <Input
              onChange={handleChange}
              name='companyCode'
              value={companyCode}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.COMPANY_CODE', '')}
              placeholder={translate(intl, 'REGISTER.COMPANY_CODE', '')}
            />
            <Input
              onChange={handleChange}
              name='vatNumber'
              value={vatNumber}
              type='text'
              className={styles.input}
              label={translate(intl, 'REGISTER.VAT_NUMBER', '')}
              placeholder={translate(intl, 'REGISTER.VAT_NUMBER', '')}
            />
            <Input
              onChange={handleChange}
              name='invoiceMode'
              value={invoiceMode}
              type='select'
              className={styles.input}
              label={translate(intl, 'REGISTER.INVOICE_MODE', '')}
              placeholder={translate(intl, 'REGISTER.INVOICE_MODE', '')}
              error={showErrors && !invoiceMode ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
              options={[{label: translate(intl, 'REGISTER.INVOICE_MODE_DAY', ''), value: 'day'}]}
              hideEmptyOption
            />
            <Input
              onChange={handleChange}
              name='newsletter'
              value={newsletter}
              type='checkbox'
              placeholder={translate(intl, 'REGISTER.NEWSLETTER', '')}
              className={styles.input}
            />
            <Input
              onChange={handleChange}
              name='agreement'
              value={agreement}
              type='checkbox'
              placeholder={translate(intl, 'REGISTER.AGREEMENT', '')}
              className={styles.input}
              error={showErrors && agreement === '0' ? translate(intl, 'VALIDATION.REQUIRED', '') : ''}
            />
          </div>
        </div>

        <div className={styles.button}>
          <Button onClick={handleSubmit} isFullWidth isLoading={isLoading} disabled={isLoading}>
            {translate(intl, 'REGISTER.REGISTER', '')}
          </Button>
        </div>
        <div className={styles.footer}>
          {translate(intl, 'REGISTER.HAVE_AN_ACCOUNT', '')}
          <span className={styles.action} onClick={onLoginClick}>
            {translate(intl, 'REGISTER.LOGIN', '')}
          </span>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.registerError,
  isLoading: state.auth.registerLoading,
  countries: state.country.countries,
  registerSuccess: state.auth.registerSuccess,
  selectedLanguage: state.auth.selectedLanguage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onRegisterSubmit: (inputs: RegisterRequest) => dispatch(authService.register(inputs)),
  onGetCountries: () => dispatch(countryService.getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
