import * as actionTypes from './actionTypes';
import {InvoiceActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Invoice} from '../../domain/Invoice';

export type InvoiceStateType = {
  invoices: Invoice[],
  getInvoicesError: HttpError,
  getInvoicesLoading: boolean,
  downloadInvoiceLoading: boolean,
  downloadInvoiceError: HttpError,
};

export type InvoiceActionType = InvoiceStateType & {
  type: InvoiceActionTypes,
};

export const initialState: InvoiceStateType = {
  invoices: [],
  getInvoicesError: null,
  getInvoicesLoading: false,
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
};

const getInvoicesStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  getInvoicesLoading: true,
});

const getInvoicesSuccess = (state: InvoiceStateType, action: InvoiceActionType): InvoiceStateType => ({
  ...state,
  getInvoicesLoading: false,
  getInvoicesError: null,
  invoices: action.invoices,
});

const getInvoicesFail = (
  state: InvoiceStateType, action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  getInvoicesError: action.getInvoicesError,
  getInvoicesLoading: false,
});

const downloadInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  downloadInvoiceLoading: true,
});

const downloadInvoiceSuccess = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
});

const downloadInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  downloadInvoiceError: action.downloadInvoiceError,
  downloadInvoiceLoading: false,
});

const reducer = (state = initialState, action: InvoiceActionType) => {
  switch (action.type) {
  case actionTypes.GET_INVOICES_START:
    return getInvoicesStart(state);
  case actionTypes.GET_INVOICES_SUCCESS:
    return getInvoicesSuccess(state, action);
  case actionTypes.GET_INVOICES_FAIL:
    return getInvoicesFail(state, action);
  case actionTypes.DOWNLOAD_INVOICE_START:
    return downloadInvoiceStart(state);
  case actionTypes.DOWNLOAD_INVOICE_SUCCESS:
    return downloadInvoiceSuccess(state);
  case actionTypes.DOWNLOAD_INVOICE_FAIL:
    return downloadInvoiceFail(state, action);
  default:
    return state;
  }
};

export default reducer;
