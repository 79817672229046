import * as actionTypes from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {User} from '../../domain/User';
import {Language} from '../../domain/Translation';

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = () => ({
  type: actionTypes.LOGIN_SUCCESS,
});

export const loginFail = (loginError: HttpError) => ({
  type: actionTypes.LOGIN_FAIL,
  loginError,
});

export const registerStart = () => ({
  type: actionTypes.REGISTER_START,
});

export const registerSuccess = () => ({
  type: actionTypes.REGISTER_SUCCESS,
});

export const registerFail = (registerError: HttpError) => ({
  type: actionTypes.REGISTER_FAIL,
  registerError,
});

export const getUserStart = () => ({
  type: actionTypes.GET_USER_START,
});

export const getUserSuccess = (user: User) => ({
  type: actionTypes.GET_USER_SUCCESS,
  user,
});

export const getUserFail = (getUserError: HttpError) => ({
  type: actionTypes.GET_USER_FAIL,
  getUserError,
});

export const setIsLoginVisible = (isLoginVisible: boolean) => ({
  type: actionTypes.SET_IS_LOGIN_VISIBLE,
  isLoginVisible,
});

export const selectLanguage = (selectedLanguage: Language) => ({
  type: actionTypes.SELECT_LANGUAGE,
  selectedLanguage,
});

export const logout = () => {
  localStorage.removeItem('token');
  return {
    type: actionTypes.LOGOUT,
  };
};
