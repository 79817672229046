export const CHECK_PRICE_START = 'CHECK_PRICE_START';
export const CHECK_PRICE_SUCCESS = 'CHECK_PRICE_SUCCESS';
export const CHECK_PRICE_FAIL = 'CHECK_PRICE_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const GET_ORDERS_START = 'GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

export const GET_CART_START = 'GET_CART_START';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAIL = 'GET_CART_FAIL';

export const GET_TRACKING_START = 'GET_TRACKING_START';
export const GET_TRACKING_SUCCESS = 'GET_TRACKING_SUCCESS';
export const GET_TRACKING_FAIL = 'GET_TRACKING_FAIL';

export const DELETE_ORDER_START = 'DELETE_ORDER_START';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

export const SELECT_ORDER = 'SELECT_ORDER';

export type OrderActionTypes =
  typeof CHECK_PRICE_START |
  typeof CHECK_PRICE_SUCCESS |
  typeof CHECK_PRICE_FAIL |
  typeof CREATE_ORDER_START |
  typeof CREATE_ORDER_SUCCESS |
  typeof CREATE_ORDER_FAIL |
  typeof GET_ORDERS_START |
  typeof GET_ORDERS_SUCCESS |
  typeof GET_ORDERS_FAIL |
  typeof GET_CART_START |
  typeof GET_CART_SUCCESS |
  typeof GET_CART_FAIL |
  typeof GET_TRACKING_START |
  typeof GET_TRACKING_SUCCESS |
  typeof GET_TRACKING_FAIL |
  typeof DELETE_ORDER_START |
  typeof DELETE_ORDER_SUCCESS |
  typeof DELETE_ORDER_FAIL |
  typeof SELECT_ORDER
;
