import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

Sentry.init({
  dsn: 'https://9d55110899cd43b0ad885222fde3d8d8@o1101437.ingest.sentry.io/6246169',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  debug: true,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
