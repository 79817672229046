import axios from '../../config/Axios/axios-instance';
import {
  getUserFail,
  getUserStart,
  getUserSuccess,
  loginFail,
  loginStart,
  loginSuccess,
  logout,
  registerFail,
  registerStart,
  registerSuccess,
} from './actions';
import {Dispatch} from 'redux';

const API_AUTH_URL = '/v1/auth';

export type LoginRequest = {
  email: string,
  password: string,
};

export type RegisterRequest = {
  name: string,
  lastname: string,
  email: string,
  password: string,
  company?: string,
  company_code?: string,
  vat_number?: string,
  address: string,
  city: string,
  postal_code: string,
  newsletter: string,
  invoice_mode: string,
  country_id: string,
};

export const login =
  (inputs: LoginRequest) => (dispatch: Dispatch) => {
    dispatch(loginStart());
    return axios.post(`${API_AUTH_URL}/login`, {
      ...inputs,
    })
      .then(async (response) => {
        localStorage.setItem('token', response.data.access_token);
        dispatch(loginSuccess());
      })
      .catch((err) => {
        dispatch(loginFail(err.response?.data?.error));
      })
    ;
  };

export const register =
  (inputs: RegisterRequest) => (dispatch: Dispatch) => {
    dispatch(registerStart());
    return axios.post(`${API_AUTH_URL}/register`, {
      ...inputs,
    })
      .then(async () => {
        dispatch(registerSuccess());
      })
      .catch((err) => {
        dispatch(registerFail(err.response.data.errors));
      })
    ;
  };

export const getUser = () => (dispatch: Dispatch) => {
  dispatch(getUserStart());
  return axios.get(`${API_AUTH_URL}/me`)
    .then(async (response) => {
      dispatch(getUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getUserFail(err.response?.data?.message));
    })
  ;
};

export const logoutSession = () => (dispatch: Dispatch) => {
  return axios.get(`${API_AUTH_URL}/logout`)
    .then(async () => {
      dispatch(logout());
    })
    .catch((err) => {
      dispatch(logout());
    })
  ;
};

export const refreshToken = () => (dispatch: Dispatch) => {
  return axios.get(`${API_AUTH_URL}/refresh`)
    .then(async (response) => {
      localStorage.setItem('token', response.data.access_token);
    })
    .catch((err) => {
    })
  ;
};

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch(logout());
};

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(loginSuccess()) : dispatch(logout());
};
