import * as actionTypes from './actionTypes';
import {AddressActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Address} from '../../domain/Address';

export type AddressStateType = {
  addressesList: Address[],
  addressesLoading: boolean;
  addressesError: HttpError;
  addressesListUpdateNeeded: boolean;
  addressCreateLoading: boolean;
  addressCreateError: HttpError;
  addressCreateSuccess: boolean;
  createdAddress: Address | null;
  addressUpdateLoading: boolean;
  addressUpdateError: HttpError;
  addressUpdateSuccess: boolean;
  addressDeleteLoading: boolean;
  addressDeleteError: HttpError;
  address: Address | null;
  addressLoading: boolean;
  addressError: HttpError;
  addressEditUpdateNeeded: boolean;
};

export type AddressActionType = AddressStateType & {
  type: AddressActionTypes;
};

export const initialState: AddressStateType = {
  addressesList: [],
  addressesLoading: true,
  addressesError: null,
  addressesListUpdateNeeded: false,
  addressCreateLoading: false,
  addressCreateError: null,
  addressCreateSuccess: false,
  createdAddress: null,
  addressUpdateLoading: false,
  addressUpdateError: null,
  addressUpdateSuccess: false,
  addressDeleteLoading: false,
  addressDeleteError: null,
  address: null,
  addressLoading: false,
  addressError: null,
  addressEditUpdateNeeded: false,
};

const fetchAddressesStart = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressesLoading: true,
  addressesList: [],
});

const fetchAddressesSuccess = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressesList: action.addressesList,
  addressesLoading: false,
  addressesError: null,
  addressesListUpdateNeeded: false,
  addressUpdateSuccess: false,
  addressCreateSuccess: false,
});

const fetchAddressesFail = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressesError: action.addressesError,
  addressesLoading: false,
});

const fetchAddressStart = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressLoading: true,
  addressUpdateSuccess: false,
  addressCreateSuccess: false,
  addressCreateError: null,
  addressUpdateError: null,
});

const fetchAddressSuccess = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  address: action.address,
  addressLoading: false,
  addressError: null,
});

const fetchAddressFail = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressError: action.addressError,
  addressLoading: false,
});

const createAddressStart = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressCreateLoading: true,
});

const createAddressSuccess = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressCreateLoading: false,
  addressCreateError: null,
  addressCreateSuccess: true,
  createdAddress: action.createdAddress,
});

const createAddressFail = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressCreateLoading: false,
  addressCreateError: action.addressCreateError,
});

const updateAddressStart = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressUpdateLoading: true,
});

const updateAddressSuccess = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressUpdateLoading: false,
  addressUpdateError: null,
  addressUpdateSuccess: true,
  addressEditUpdateNeeded: true,
});

const updateAddressFail = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressUpdateLoading: false,
  addressUpdateError: action.addressUpdateError,
});

const deleteAddressStart = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressDeleteLoading: true,
});

const deleteAddressSuccess = (state: AddressStateType): AddressStateType => ({
  ...state,
  addressDeleteLoading: false,
  addressDeleteError: null,
  addressesListUpdateNeeded: true,
});

const deleteAddressFail = (
  state: AddressStateType,
  action: AddressActionType,
): AddressStateType => ({
  ...state,
  addressDeleteLoading: false,
  addressDeleteError: action.addressDeleteError,
});

const reducer = (state = initialState, action: AddressActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_ADDRESSES_START:
    return fetchAddressesStart(state);
  case actionTypes.FETCH_ADDRESSES_SUCCESS:
    return fetchAddressesSuccess(state, action);
  case actionTypes.FETCH_ADDRESSES_FAIL:
    return fetchAddressesFail(state, action);
  case actionTypes.FETCH_ADDRESS_START:
    return fetchAddressStart(state);
  case actionTypes.FETCH_ADDRESS_SUCCESS:
    return fetchAddressSuccess(state, action);
  case actionTypes.FETCH_ADDRESS_FAIL:
    return fetchAddressFail(state, action);
  case actionTypes.CREATE_ADDRESS_START:
    return createAddressStart(state);
  case actionTypes.CREATE_ADDRESS_SUCCESS:
    return createAddressSuccess(state, action);
  case actionTypes.CREATE_ADDRESS_FAIL:
    return createAddressFail(state, action);
  case actionTypes.UPDATE_ADDRESS_START:
    return updateAddressStart(state);
  case actionTypes.UPDATE_ADDRESS_SUCCESS:
    return updateAddressSuccess(state);
  case actionTypes.UPDATE_ADDRESS_FAIL:
    return updateAddressFail(state, action);
  case actionTypes.DELETE_ADDRESS_START:
    return deleteAddressStart(state);
  case actionTypes.DELETE_ADDRESS_SUCCESS:
    return deleteAddressSuccess(state);
  case actionTypes.DELETE_ADDRESS_FAIL:
    return deleteAddressFail(state, action);
  default:
    return state;
  }
};

export default reducer;
