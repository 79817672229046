import React, {Suspense, useEffect, useMemo} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Layout from '../../common/Layout/Layout';
import {connect} from 'react-redux';
import Loader from '../../common/Loader/Loader';
import * as authService from '../../store/auth/service';
import {StoreState} from '../StoreProvider/StoreProvider';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {routes} from './routes';
import {User} from '../../domain/User';
import {IntlProvider} from 'react-intl';
import {translationsEN, translationsLT} from '../translations/translations';
import {Language} from '../../domain/Translation';

const Homepage = React.lazy(() => import('../../pages/Homepage/Homepage'));
const AboutUs = React.lazy(() => import('../../pages/AboutUs/AboutUs'));
const Business = React.lazy(() => import('../../pages/Business/Business'));
const Contact = React.lazy(() => import('../../pages/Contact/Contact'));
const FAQ = React.lazy(() => import('../../pages/FAQ/FAQ'));
const Calculate = React.lazy(() => import('../../pages/Calculate/Calculate'));
const ProfileAccount = React.lazy(() => import('../../pages/Profile/Account/Account'));
const ProfileBasket = React.lazy(() => import('../../pages/Profile/Basket/Basket'));
const ProfileInvoices = React.lazy(() => import('../../pages/Profile/Invoices/Invoices'));
const ProfileOrders = React.lazy(() => import('../../pages/Profile/Orders/Orders'));
const ProfileTracking = React.lazy(() => import('../../pages/Profile/Tracking/Tracking'));
const ProfileBalance = React.lazy(() => import('../../pages/Profile/Balance/Balance'));
const CheckoutConfirmation = React.lazy(() => import('../../pages/Checkout/Confirmation/Confirmation'));
const CheckoutService = React.lazy(() => import('../../pages/Checkout/Service/Service'));
const CheckoutDetails = React.lazy(() => import('../../pages/Checkout/Details/Details'));
const CheckoutPayment = React.lazy(() => import('../../pages/Checkout/Payment/Payment'));
const ContentPage = React.lazy(() => import('../../pages/ContentPage/ContentPage'));
const EuropeanDonation = React.lazy(() => import('../../pages/EuropeanDonation/EuropeanDonation'));

export type Props = {
  isAuthenticated: boolean,
  isInitCompleted: boolean,
  user: null | User,
  onGetUser: () => void,
  selectedLanguage: Language,
};

export const Router = ({
  user,
  isAuthenticated,
  onGetUser,
  isInitCompleted,
  selectedLanguage,
}: Props) => {
  useEffect(() => {
    if (isAuthenticated && !user) {
      onGetUser();
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    onGetUser();
  }, []);

  const mappedTranslations = useMemo(() => {
    if (selectedLanguage === 'lt') {
      return translationsLT.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.alias]: item.value,
          }),
        {},
      );
    }

    return translationsEN.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.alias]: item.value,
        }),
      {},
    );
  }, [selectedLanguage]);

  return (
    <BrowserRouter basename='/'>
      {isInitCompleted ? (
        <IntlProvider messages={mappedTranslations} locale={selectedLanguage} defaultLocale="lt">
          <Suspense fallback={<Loader isLoading isFullScreen/>}>
            <Layout>
              {!!user ? (
                <Routes>
                  <Route path={routes.homepage} element={<Homepage/>}/>
                  <Route path={routes.aboutUs} element={<AboutUs/>}/>
                  <Route path={routes.business} element={<Business/>}/>
                  <Route path={routes.faq} element={<FAQ/>}/>
                  <Route path={routes.contact} element={<Contact/>}/>
                  <Route path={routes.calculate} element={<Calculate/>}/>
                  <Route path={routes.account} element={<ProfileAccount/>}/>
                  <Route path={routes.invoices} element={<ProfileInvoices/>}/>
                  <Route path={routes.orders} element={<ProfileOrders/>}/>
                  <Route path={routes.tracking} element={<ProfileTracking/>}/>
                  <Route path={routes.balance} element={<ProfileBalance/>}/>
                  <Route path={routes.basket} element={<ProfileBasket/>}/>
                  <Route path={routes.details} element={<CheckoutDetails/>}/>
                  <Route path={routes.payment} element={<CheckoutPayment/>}/>
                  <Route path={routes.confirmation} element={<CheckoutConfirmation/>}/>
                  <Route path={routes.checkout} element={<CheckoutService/>}/>
                  <Route path={routes.contentPage} element={<ContentPage/>}/>
                  <Route path={routes.europeanDonation} element={<EuropeanDonation/>}/>
                  <Route path="*" element={<Navigate to={routes.homepage}/>}/>
                </Routes>
              ) : (
                <Routes>
                  <Route path={routes.homepage} element={<Homepage/>}/>
                  <Route path={routes.aboutUs} element={<AboutUs/>}/>
                  <Route path={routes.business} element={<Business/>}/>
                  <Route path={routes.faq} element={<FAQ/>}/>
                  <Route path={routes.contact} element={<Contact/>}/>
                  <Route path={routes.calculate} element={<Calculate/>}/>
                  <Route path={routes.contentPage} element={<ContentPage/>}/>
                  <Route path={routes.europeanDonation} element={<EuropeanDonation/>}/>
                  <Route path="*" element={<Navigate to={routes.homepage}/>}/>
                </Routes>
              )}
            </Layout>
          </Suspense>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen/>
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isInitCompleted: state.auth.isInitCompleted,
  isLoading: state.auth.getUserLoading,
  user: state.auth.user,
  selectedLanguage: state.auth.selectedLanguage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onGetUser: () => dispatch(authService.getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
