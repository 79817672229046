export const GET_INVOICES_START = 'GET_INVOICES_START';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL';

export const DOWNLOAD_INVOICE_START = 'DOWNLOAD_INVOICE_START';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';

export type InvoiceActionTypes =
  typeof GET_INVOICES_START |
  typeof GET_INVOICES_SUCCESS |
  typeof GET_INVOICES_FAIL |
  typeof DOWNLOAD_INVOICE_START |
  typeof DOWNLOAD_INVOICE_SUCCESS |
  typeof DOWNLOAD_INVOICE_FAIL
;
