import React, {ReactNode} from 'react';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import styles from './Loader.module.scss';

export type Props = {
  children?: ReactNode,
  isLoading: boolean,
  isFullScreen?: boolean,
  height?: number,
  className?: string,
};

const Loader = ({children, isLoading, isFullScreen, height, className}: Props) => (
  <div
    className={cx(styles.loader, className, {[styles.fullScreen]: isFullScreen})}
    style={{height: height}}
  >
    {isLoading && (
      <div className={styles.loaderIcon}>
        <FontAwesomeIcon icon={faCircleNotch} spin size="lg"/>
      </div>
    )}
    <div className={cx({[styles.loaderChildren]: isLoading})}>
      {children}
    </div>
  </div>
);

export default Loader;
