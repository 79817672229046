import React, {ChangeEvent} from 'react';
import styles from './Input.module.scss';
import cx from 'classnames';

export type Props = {
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void,
  value: string;
  type: 'number' | 'text' | 'textarea' | 'password' | 'select' | 'checkbox',
  className?: string,
  label?: string,
  name?: string,
  postfix?: string,
  error?: string,
  options?: Array<{label: string, value: string | number}>
  hideEmptyOption?: boolean,
};

export const Input = ({
  placeholder,
  onChange,
  value,
  type,
  className,
  label,
  name,
  postfix,
  error,
  options,
  hideEmptyOption,
}: Props) => {
  return (
    <div className={cx(styles.inputContainer, className)}>
      {label && (
        <div className={cx(styles.label, {[styles.errorLabel]: error})}>
          {label}
        </div>
      )}
      <div className={styles.inputWrapper}>
        {type === 'textarea' && (
          <textarea
            className={cx(styles.textarea, {[styles.error]: !!error})}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
          />
        )}
        {type === 'checkbox' && (
          <div className={styles.checkboxContainer}>
            <input
              className={cx(styles.checkbox, {[styles.error]: !!error})}
              onChange={onChange}
              checked={value === '1'}
              value={value}
              type={type}
              name={name}
            />
            <label>{placeholder}</label>
          </div>
        )}
        {type === 'select' && (
          <select
            className={cx(styles.input, {[styles.error]: !!error})}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
          >
            {!hideEmptyOption && (
              <option value=''></option>
            )}
            {options?.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        )}
        {(type === 'text' || type === 'number' || type === 'password') && (
          <input
            className={cx(styles.input, {[styles.error]: !!error})}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            type={type}
            name={name}
          />
        )}
        {postfix && (
          <div className={styles.postfix}>
            {postfix}
          </div>
        )}
      </div>
      {!!error && (
        <div className={styles.errorText}>
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;
