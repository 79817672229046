import React from 'react';
import styles from './Footer.module.scss';
import logoWithText from '../../assets/logo_with_text.svg';
import {Content} from '../../domain/Content';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {translate} from '../../utility/messageTranslator/translate';
import {StoreState} from '../../config/StoreProvider/StoreProvider';
import {connect} from 'react-redux';
import {Language} from '../../domain/Translation';

type Props = {
  contentPages: Content[],
  selectedLanguage: Language,
}

const Footer = ({contentPages, selectedLanguage}: Props) => {
  const intl = useIntl();

  const mappedCategories = _.uniqBy(_.flatten(contentPages.map((contentPage) => contentPage.categories)), 'id');
  const getPagesByCategory = (categoryId: number) =>
    contentPages.filter((contentPage) => contentPage.categories.find((category) => category.id === categoryId));

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.logoContainer}>
          <img src={logoWithText} alt="Packsender" />
        </div>
        <div className={styles.contentColumns}>
          {mappedCategories.map((mappedCategory) => (
            <div key={mappedCategory.id} className={styles.column}>
              <div className={styles.title}>
                {mappedCategory.name[selectedLanguage]}
              </div>
              {getPagesByCategory(mappedCategory.id).map((item) => {
                if (!item.slug) {
                  return (
                    <div key={item.id} className={styles.emptyLink}>
                      {item.title[selectedLanguage]}
                    </div>
                  );
                }

                return (
                  <Link key={item.id} to={item.slug}>
                    {item.title[selectedLanguage]}
                  </Link>
                );
              })}
            </div>
          ))}
        </div>
        <div className={styles.copyright}>
          {translate(intl, 'FOOTER.COPYRIGHT', '')}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLanguage: state.auth.selectedLanguage,
});

export default connect(mapStateToProps)(Footer);
