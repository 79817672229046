import * as actionTypes from './actionTypes';
import {LabelActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';

export type LabelStateType = {
  downloadLabelLoading: boolean,
  downloadLabelError: HttpError,
};

export type LabelActionType = LabelStateType & {
  type: LabelActionTypes,
};

export const initialState: LabelStateType = {
  downloadLabelLoading: false,
  downloadLabelError: null,
};

const downloadLabelStart = (state: LabelStateType): LabelStateType => ({
  ...state,
  downloadLabelLoading: true,
});

const downloadLabelSuccess = (
  state: LabelStateType,
): LabelStateType => ({
  ...state,
  downloadLabelLoading: false,
  downloadLabelError: null,
});

const downloadLabelFail = (
  state: LabelStateType,
  action: LabelActionType,
): LabelStateType => ({
  ...state,
  downloadLabelError: action.downloadLabelError,
  downloadLabelLoading: false,
});

const reducer = (state = initialState, action: LabelActionType) => {
  switch (action.type) {
  case actionTypes.DOWNLOAD_LABEL_START:
    return downloadLabelStart(state);
  case actionTypes.DOWNLOAD_LABEL_SUCCESS:
    return downloadLabelSuccess(state);
  case actionTypes.DOWNLOAD_LABEL_FAIL:
    return downloadLabelFail(state, action);
  default:
    return state;
  }
};

export default reducer;
