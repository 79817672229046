export const FETCH_ADDRESSES_START = 'FETCH_ADDRESSES_START';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAIL = 'FETCH_ADDRESSES_FAIL';

export const FETCH_ADDRESS_START = 'FETCH_ADDRESS_START';
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESS_FAIL = 'FETCH_ADDRESS_FAIL';

export const CREATE_ADDRESS_START = 'CREATE_ADDRESS_START';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAIL = 'CREATE_ADDRESS_FAIL';

export const UPDATE_ADDRESS_START = 'UPDATE_ADDRESSES_START';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESSES_SUCCESS';
export const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESSES_FAIL';

export const DELETE_ADDRESS_START = 'DELETE_ADDRESS_START';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

export type AddressActionTypes =
  typeof FETCH_ADDRESSES_START |
  typeof FETCH_ADDRESSES_SUCCESS |
  typeof FETCH_ADDRESSES_FAIL |
  typeof FETCH_ADDRESS_START |
  typeof FETCH_ADDRESS_SUCCESS |
  typeof FETCH_ADDRESS_FAIL |
  typeof CREATE_ADDRESS_START |
  typeof CREATE_ADDRESS_SUCCESS |
  typeof CREATE_ADDRESS_FAIL |
  typeof UPDATE_ADDRESS_START |
  typeof UPDATE_ADDRESS_SUCCESS |
  typeof UPDATE_ADDRESS_FAIL |
  typeof DELETE_ADDRESS_START |
  typeof DELETE_ADDRESS_SUCCESS |
  typeof DELETE_ADDRESS_FAIL

