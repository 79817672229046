export const routes = {
  homepage: '/',
  aboutUs: '/about-us',
  business: '/business',
  faq: '/faq',
  contact: '/contact',
  calculate: '/calculate',
  europeanDonation: '/eu-projects',
  basket: '/profile',
  orders: '/profile/orders',
  tracking: '/profile/tracking',
  account: '/profile/account',
  invoices: '/profile/invoices',
  balance: '/profile/balance',
  checkout: '/checkout',
  details: '/checkout/details',
  confirmation: '/checkout/confirmation',
  payment: '/checkout/payment',
  contentPage: '/:slug',
};
