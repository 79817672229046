import React, {ReactNode} from 'react';
import {Provider} from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, {AuthStateType} from '../../store/auth/reducer';
import countryReducer, {CountryStateType} from '../../store/country/reducer';
import faqReducer, {FaqStateType} from '../../store/faq/reducer';
import userReducer, {UserStateType} from '../../store/user/reducer';
import orderReducer, {OrderStateType} from '../../store/order/reducer';
import addressReducer, {AddressStateType} from '../../store/address/reducer';
import cmsReducer, {ContentStateType} from '../../store/cms/reducer';
import contactReducer, {ContactStateType} from '../../store/contact/reducer';
import invoiceReducer, {InvoiceStateType} from '../../store/invoice/reducer';
import balanceReducer, {BalanceStateType} from '../../store/balance/reducer';
import labelReducer, {LabelStateType} from '../../store/label/reducer';
import instance from '../Axios/axios-instance';
import * as authActions from '../../store/auth/actions';
import * as authService from '../../store/auth/service';

export type StoreState = {
  auth: AuthStateType,
  country: CountryStateType,
  faq: FaqStateType,
  user: UserStateType,
  order: OrderStateType,
  address: AddressStateType,
  cms: ContentStateType,
  contact: ContactStateType,
  invoice: InvoiceStateType,
  balance: BalanceStateType,
  label: LabelStateType,
};

type Props = {
  children?: ReactNode,
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  country: countryReducer,
  faq: faqReducer,
  user: userReducer,
  order: orderReducer,
  address: addressReducer,
  cms: cmsReducer,
  contact: contactReducer,
  invoice: invoiceReducer,
  balance: balanceReducer,
  label: labelReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store: Store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk),
));

const {dispatch} = store;
instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 && !error.response.config.url.includes(['/refresh'])) {
    // @ts-ignore
    dispatch(authService.refreshToken());

    return Promise.reject(error);
  }

  if (error.response.status === 401 && error.response.config.url === '/refresh') {
    dispatch(authActions.logout());
  }

  return Promise.reject(error);
});

const StoreProvider = ({children}: Props) => (
  <Provider store={store}>
    {children}
  </Provider>
);

export default StoreProvider;
