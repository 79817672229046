import {IntlShape} from 'react-intl';

export const translate = (
  intl: IntlShape,
  id: string,
  defaultMessage: string,
) =>
  intl.formatMessage({
    id,
    defaultMessage,
  });
