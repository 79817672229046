import React, {ReactNode, useEffect, useState} from 'react';
import styles from './Layout.module.scss';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import Login from '../../components/Login/Login';
import cx from 'classnames';
import Register from '../../components/Register/Register';
import {StoreState} from '../../config/StoreProvider/StoreProvider';
import {connect} from 'react-redux';
import {User} from '../../domain/User';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {setIsLoginVisible} from '../../store/auth/actions';
import * as cmsService from '../../store/cms/service';
import {Content} from '../../domain/Content';

export type Props = {
  children: ReactNode,
  user: null | User,
  isLoginVisible: boolean,
  onSetIsLoginVisible: () => void,
  contentPages: Content[],
  onGetContentPages: () => void,
  isInitCompleted: boolean,
}

const Layout = ({
  children,
  user,
  isLoginVisible,
  onSetIsLoginVisible,
  onGetContentPages,
  contentPages,
  isInitCompleted,
}: Props) => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);

  const isModalOpen = isLoginOpen || isRegisterOpen;

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = isModalOpen ? 'hidden' : '';
    window.scrollTo(0, 0);

    if (!isModalOpen) {
      onSetIsLoginVisible();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (user) {
      setIsLoginOpen(false);
      setIsRegisterOpen(false);
    }
  }, [user]);

  useEffect(() => {
    if (isLoginVisible) {
      setIsLoginOpen(true);
    }
  }, [isLoginVisible]);

  useEffect(() => {
    onGetContentPages();
  }, [onGetContentPages]);

  const handleLoginClick = () => {
    setIsRegisterOpen(false);
    setIsLoginOpen(true);
  };

  const handleRegisterClick = () => {
    setIsLoginOpen(false);
    setIsRegisterOpen(true);
  };

  return (
    <>
      <div className={cx(styles.layout, {[styles.isModalOpen]: isModalOpen})}>
        <Navigation onLoginClick={() => setIsLoginOpen(true)} onRegisterClick={() => setIsRegisterOpen(true)}/>
        {children}
        <Footer contentPages={contentPages}/>
      </div>
      {isLoginOpen && (
        <Login onClose={() => setIsLoginOpen(false)} onRegisterClick={handleRegisterClick}/>
      )}
      {isRegisterOpen && (
        <Register onClose={() => setIsRegisterOpen(false)} onLoginClick={handleLoginClick}/>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.auth.user,
  isLoginVisible: state.auth.isLoginVisible,
  contentPages: state.cms.contents,
  isInitCompleted: state.auth.isInitCompleted,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetIsLoginVisible: () => dispatch(setIsLoginVisible(false)),
  onGetContentPages: () => dispatch(cmsService.getContents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
