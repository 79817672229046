import React, {ReactNode} from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';

export type Props = {
  children: ReactNode,
  isFullWidth?: boolean,
  onClick: (event: any) => void,
  isSecondary?: boolean,
  isWhite?: boolean,
  isLoading?: boolean,
  disabled?: boolean,
  className?: string,
};

export const Button = ({
  children,
  isFullWidth,
  onClick,
  isSecondary,
  isWhite,
  isLoading,
  disabled,
  className,
}: Props) => {
  return (
    <button
      className={cx(styles.button, className, {
        [styles.fullWidth]: isFullWidth,
        [styles.secondary]: isSecondary,
        [styles.white]: isWhite,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.buttonContent}>
        {isLoading && (
          <div className={styles.loaderIcon}>
            <FontAwesomeIcon icon={faCircleNotch} spin size="lg"/>
          </div>
        )}
        {children}
      </div>
    </button>
  );
};

export default Button;
