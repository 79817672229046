import * as actionTypes from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Content} from '../../domain/Content';

export const getContentsStart = () => ({
  type: actionTypes.GET_CONTENTS_START,
});

export const getContentsSuccess = (contents: Content[]) => ({
  type: actionTypes.GET_CONTENTS_SUCCESS,
  contents,
});

export const getContentsFail = (getContentsError: HttpError) => ({
  type: actionTypes.GET_CONTENTS_FAIL,
  getContentsError,
});
