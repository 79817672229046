import React from 'react';
import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';

const App = () => (
  <StoreProvider>
    <Router/>
  </StoreProvider>
);

export default App;
