import React, {ReactNode} from 'react';
import styles from './Alert.module.scss';
import cn from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

export type Props = {
  children?: ReactNode,
  className?: string,
  variant: 'danger',
};

const Alert = ({children, className, variant}: Props) => {
  const getIcon = () => {
    return faExclamationCircle;
  };

  return (
    <div className={cn(styles.alert, styles[variant], className)}>
      <div className={styles.icon}>
        <FontAwesomeIcon
          icon={getIcon()}
          fixedWidth
          size='xs'
        />
      </div>
      <div className={styles.message}>
        {children}
      </div>
    </div>
  );
};

Alert.defaultProps = {
  isText: true,
  capitalize: true,
};

export default Alert;
