import React, {ChangeEvent, useState} from 'react';
import styles from './Login.module.scss';
import Input from '../../common/Input/Input';
import Modal from '../../common/Modal/Modal';
import Button from '../../common/Button/Button';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {LoginRequest} from '../../store/auth/service';
import {StoreState} from '../../config/StoreProvider/StoreProvider';
import * as authService from '../../store/auth/service';
import {HttpError} from '../../config/Axios/axios-instance';
import Alert from '../../common/Alert/Alert';
import {useIntl} from 'react-intl';
import {translate} from '../../utility/messageTranslator/translate';

type Props = {
  onClose: () => void,
  onLoginSubmit: (inputs: LoginRequest) => void,
  onRegisterClick: () => void,
  isLoading: boolean,
  error: HttpError,
}

export const Login = ({onClose, onLoginSubmit, onRegisterClick, isLoading, error}: Props) => {
  const intl = useIntl();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    event.preventDefault();

    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }

    if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = () => {
    onLoginSubmit({
      email, password,
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit();
  };

  return (
    <Modal onClose={onClose} title={translate(intl, 'LOGIN.TITLE', '')}>
      {!!error && (
        <Alert variant='danger'>
          {translate(intl, 'LOGIN.INCORRECT_LOGIN_DATA', '')}
        </Alert>
      )}
      <form onSubmit={handleFormSubmit} className={styles.loginContainer} noValidate>
        <Input
          onChange={handleChange}
          name='email'
          value={email}
          type='text'
          className={styles.input}
          label={translate(intl, 'LOGIN.FIELD_EMAIL', '')}
          placeholder={translate(intl, 'LOGIN.PLACEHOLDER_EMAIL', '')}
        />
        <Input
          onChange={handleChange}
          name='password'
          value={password}
          type='password'
          className={styles.input}
          label={translate(intl, 'LOGIN.FIELD_PASSWORD', '')}
          placeholder={translate(intl, 'LOGIN.PLACEHOLDER_PASSWORD', '')}
        />
        <div className={styles.button}>
          <Button onClick={handleSubmit} isFullWidth isLoading={isLoading} disabled={isLoading}>
            {translate(intl, 'LOGIN.LOGIN', '')}
          </Button>
        </div>
        <div className={styles.footer}>
          {translate(intl, 'LOGIN.NO_ACCOUNT', '')}
          <span className={styles.action} onClick={onRegisterClick}>
            {translate(intl, 'LOGIN.REGISTER', '')}
          </span>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.loginError,
  isLoading: state.auth.loginLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLoginSubmit: (inputs: LoginRequest) => dispatch(authService.login(inputs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
