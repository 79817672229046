import React, {ReactNode} from 'react';
import styles from './Modal.module.scss';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Props = {
  onClose: () => void,
  children: ReactNode,
  title: string,
}

export const Modal = ({onClose, children, title}: Props) => {
  return (
    <>
      <div className={styles.modalContainer}>
        <div className={styles.title}>
          {title}
          <FontAwesomeIcon
            icon={faXmark}
            size="lg"
            onClick={onClose}
          />
        </div>
        {children}
        <div className={styles.footer}/>
      </div>
      <div className={styles.backdrop} onClick={onClose}/>
    </>
  );
};

export default Modal;
