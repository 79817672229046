import {Translation} from '../../domain/Translation';

export const translationsLT: Translation[] = [
  {
    alias: 'VALIDATION.REQUIRED',
    value: 'Laukas privalomas',
  },
  {
    alias: 'VALIDATION.EMAIL',
    value: 'Įvestas el. pašto adresas yra negalimas',
  },
  {
    alias: 'VALIDATION.PASSWORDS_DO_NOT_MATCH',
    value: 'Slaptažodžiai nesutampa',
  },
  {
    alias: 'FOOTER.COPYRIGHT',
    value: '© Visos teisės saugomos 2022',
  },
  {
    alias: 'NAVIGATION.ABOUT_US',
    value: 'Apie mus',
  },
  {
    alias: 'NAVIGATION.BUSINESS',
    value: 'Verslui',
  },
  {
    alias: 'NAVIGATION.FAQ',
    value: 'D.U.K',
  },
  {
    alias: 'NAVIGATION.CONTACTS',
    value: 'Kontaktai',
  },
  {
    alias: 'NAVIGATION.MY_ACCOUNT',
    value: 'Mano paskyra',
  },
  {
    alias: 'NAVIGATION.LOGOUT',
    value: 'Atsijungti',
  },
  {
    alias: 'NAVIGATION.LOGIN',
    value: 'Prisijungti',
  },
  {
    alias: 'NAVIGATION.REGISTER',
    value: 'Registruotis',
  },
  {
    alias: 'NAVIGATION.EUROPEAN_UNION_PROJECTS',
    value: 'ES projektai',
  },
  {
    alias: 'ADDRESS_BLOCK.SENDER',
    value: 'Siuntėjas (asmuo)',
  },
  {
    alias: 'ADDRESS_BLOCK.PHONE',
    value: 'Telefonas',
  },
  {
    alias: 'ADDRESS_BLOCK.COUNTRY',
    value: 'Šalis',
  },
  {
    alias: 'ADDRESS_BLOCK.ADDRESS',
    value: 'Adresas',
  },
  {
    alias: 'ADDRESS_BLOCK.CITY',
    value: 'Miesto/kaimo pav.',
  },
  {
    alias: 'ADDRESS_BLOCK.POST_CODE',
    value: 'Pašto kodas',
  },
  {
    alias: 'ADDRESS_FORM.NAME',
    value: 'Vardas',
  },
  {
    alias: 'ADDRESS_FORM.LAST_NAME',
    value: 'Pavardė',
  },
  {
    alias: 'ADDRESS_FORM.ADDRESS',
    value: 'Adresas',
  },
  {
    alias: 'ADDRESS_FORM.CITY',
    value: 'Miestas',
  },
  {
    alias: 'ADDRESS_FORM.REGION',
    value: 'Regionas',
  },
  {
    alias: 'ADDRESS_FORM.POST_CODE',
    value: 'Pašto kodas',
  },
  {
    alias: 'ADDRESS_FORM.PHONE',
    value: 'Telefono numeris',
  },
  {
    alias: 'ADDRESS_FORM.EMAIL',
    value: 'El. paštas',
  },
  {
    alias: 'ADDRESS_FORM.COUNTRY',
    value: 'Šalis',
  },
  {
    alias: 'ADDRESS_FORM.UPDATE',
    value: 'Atnaujinti',
  },
  {
    alias: 'ADDRESS_FORM.CREATE',
    value: 'Sukurti',
  },
  {
    alias: 'ADDRESS_FORM.CANCEL',
    value: 'Atšaukti',
  },
  {
    alias: 'ADDRESS_ITEM.SELECT',
    value: 'Pasirinkti',
  },
  {
    alias: 'ADDRESS_ITEM.EDIT',
    value: 'Pakeisti',
  },
  {
    alias: 'ADDRESS_ITEM.DELETE',
    value: 'Ištrinti',
  },
  {
    alias: 'CONFIRMATION.PICK_UP_ADDRESS',
    value: 'Paėmimo adresas',
  },
  {
    alias: 'CONFIRMATION.ADD_ADDRESS',
    value: 'Pridėti adresą',
  },
  {
    alias: 'CONFIRMATION.DELIVERY_ADDRESS',
    value: 'Pristatymo adresas',
  },
  {
    alias: 'CONFIRMATION.SHIPMENT_DATA',
    value: 'Siuntos duomenys',
  },
  {
    alias: 'CONFIRMATION.TOTAL',
    value: 'Viso',
  },
  {
    alias: 'CONFIRMATION.FROM',
    value: 'Iš',
  },
  {
    alias: 'CONFIRMATION.TO',
    value: 'Į',
  },
  {
    alias: 'CONFIRMATION.AVERAGE_DELIVERY',
    value: 'Pristatymas vidutiniškai trunka',
  },
  {
    alias: 'CONFIRMATION.CONFIRM',
    value: 'Patvirtinti',
  },
  {
    alias: 'DETAILS.SHIPMENT_DETAILS',
    value: 'Siuntos duomenys',
  },
  {
    alias: 'DETAILS.PICK_UP_FROM',
    value: 'Paėmimas iš',
  },
  {
    alias: 'DETAILS.DELIVERY_TO',
    value: 'Pristatymas į',
  },
  {
    alias: 'DETAILS.SERVICE',
    value: 'Paslauga',
  },
  {
    alias: 'DETAILS.COURIER',
    value: 'Kurjeris',
  },
  {
    alias: 'DETAILS.PICK_UP',
    value: 'Paėmimas',
  },
  {
    alias: 'DETAILS.DELIVERY',
    value: 'Pristatymas',
  },
  {
    alias: 'DETAILS.PRICE',
    value: 'Kaina',
  },
  {
    alias: 'DETAILS.VAT',
    value: 'PVM',
  },
  {
    alias: 'DETAILS.CONFIRM',
    value: 'Patvirtinti',
  },
  {
    alias: 'DETAILS_BLOCK.EDIT_DATA',
    value: 'Keisti duomenis',
  },
  {
    alias: 'PROGRESS_BAR.SERVICE_SELECTION',
    value: 'Paslaugos pasirinkimas',
  },
  {
    alias: 'PROGRESS_BAR.SHIPMENT_DETAILS',
    value: 'Siuntos detalės',
  },
  {
    alias: 'PROGRESS_BAR.ORDER_CONFIRMATION',
    value: 'Užsakymo patvirtinimas',
  },
  {
    alias: 'PROGRESS_BAR.PAYMENT',
    value: 'Apmokėjimas',
  },
  {
    alias: 'SERVICE.DELIVERY',
    value: 'Pristatymas',
  },
  {
    alias: 'SERVICE.SELECT',
    value: 'Pasirinkti',
  },
  {
    alias: 'SERVICE.NO_SERVICES',
    value: 'Nebuvo rasta galimų paslaugų',
  },
  {
    alias: 'SINGLE_SERVICE.VAT',
    value: 'PVM',
  },
  {
    alias: 'INFO_BLOCK.CONTACT_US',
    value: 'Susisiekite su mumis',
  },
  {
    alias: 'CONTACT.CONTACT_US',
    value: 'Susisiekite su mumis',
  },
  {
    alias: 'CONTACT.NAME',
    value: 'Vardas',
  },
  {
    alias: 'CONTACT.EMAIL',
    value: 'El. paštas',
  },
  {
    alias: 'CONTACT.PHONE',
    value: 'Telefono numeris',
  },
  {
    alias: 'CONTACT.MESSAGE',
    value: 'Žinutė',
  },
  {
    alias: 'CONTACT.SEND_MESSAGE',
    value: 'Siųsti žinutę',
  },
  {
    alias: 'CONTACT_CONTENT.COMPANY_CODE',
    value: 'Įmonės kodas',
  },
  {
    alias: 'FAQ_CONTENT.ENTER_QUESTION',
    value: 'Įveskite klausimą',
  },
  {
    alias: 'FAQ_CONTENT.SUBTITLE',
    value: 'Pasirinkite kategoriją, kad greitai rastumėte atsakymą',
  },
  {
    alias: 'HEADER.CONTENT_TEXT',
    value: 'Logistikos maršrutų ir optimaliausių pasiūlymų sugeneravimo sistema',
  },
  {
    alias: 'HEADER.MORE',
    value: 'Plačiau',
  },
  {
    alias: 'HOW_IT_WORKS.CALCULATE_PRICE_TITLE',
    value: 'Skaičiuokite kainą',
  },
  {
    alias: 'HOW_IT_WORKS.CALCULATE_PRICE_DESCRIPTION',
    value: 'Pateikite savo ir gavėjo pašto kodus, pasirinkite ' +
      'pakuotės tipą bei įrašykite matmenis. Pagal siuntos dydį bei ' +
      'pristatymo adresą, jums bus pateikti kurjerių pasiūlymai ir paslaugos kaina.',
  },
  {
    alias: 'HOW_IT_WORKS.PLACE_ORDER_TITLE',
    value: 'Pateikite užsakymą',
  },
  {
    alias: 'HOW_IT_WORKS.PLACE_ORDER_DESCRIPTION',
    value: 'Pasirinkite Jus labiausiai tenkinantį pasiūlymą, užsisakykite siuntinio ' +
      'pristatymo paslaugą, ir, esant poreikiui, papildomas paslaugas – papildomą ' +
      'siuntos draudimą, pristatymo nuo durų iki durų paslaugą ir pan.',
  },
  {
    alias: 'HOW_IT_WORKS.ATTACH_DOCUMENTS_TITLE',
    value: 'Priklijuokite dokumentus',
  },
  {
    alias: 'HOW_IT_WORKS.ATTACH_DOCUMENTS_DESCRIPTION',
    value: 'Per 15-20 min nuo užsakymo apmokėjimo, el. paštu gausite siuntos ' +
      'dokumentus. Būtinai juos atsispausdinkite ir pritvirtinkite matomoje ' +
      'vietoje ant kelionei tinkamai paruoštos siuntos.',
  },
  {
    alias: 'HOW_IT_WORKS.GIVE_PACKAGE_TITLE',
    value: 'Paduokite siuntą kurjeriui',
  },
  {
    alias: 'HOW_IT_WORKS.GIVE_PACKAGE_DESCRIPTION',
    value: 'Pristatymui paruoštą siuntą perduokite jos paimti atvykusiam ' +
      'kurjeriui arba pristatykite į artimiausią siuntinių surinkimo vietą.',
  },
  {
    alias: 'HOW_IT_WORKS.HOW_IT_WORKS',
    value: 'Kaip tai veikia',
  },
  {
    alias: 'LOGIN.TITLE',
    value: 'Prisijungti',
  },
  {
    alias: 'LOGIN.INCORRECT_LOGIN_DATA',
    value: 'Neteisingi prisijungimo duomenys',
  },
  {
    alias: 'LOGIN.FIELD_EMAIL',
    value: 'El. paštas',
  },
  {
    alias: 'LOGIN.PLACEHOLDER_EMAIL',
    value: 'Įveskite el. paštą',
  },
  {
    alias: 'LOGIN.FIELD_PASSWORD',
    value: 'Slaptažodis',
  },
  {
    alias: 'LOGIN.PLACEHOLDER_PASSWORD',
    value: 'Įveskite slaptažodį',
  },
  {
    alias: 'LOGIN.LOGIN',
    value: 'Prisijungti',
  },
  {
    alias: 'LOGIN.NO_ACCOUNT',
    value: 'Neturite paskyros?',
  },
  {
    alias: 'LOGIN.REGISTER',
    value: 'Registruokitės',
  },
  {
    alias: 'NOT_FOUND.PAGE_NOT_FOUND',
    value: 'Puslapis nerastas',
  },
  {
    alias: 'NOT_FOUND.PAGE_NOT_FOUND_CONTENT',
    value: 'Puslapis, kurio ieškote, šiuo metu nepasiekiamas, yra ištrintas ' +
      'arba pakeista jo dabartinė nuoroda.',
  },
  {
    alias: 'NOT_FOUND.GO_BACK_TO_HOMEPAGE',
    value: 'Grįžti į pradinį puslapį',
  },
  {
    alias: 'PACKAGE_CALCULATOR.TITLE',
    value: 'Siuntos skaičiuoklė',
  },
  {
    alias: 'PACKAGE_CALCULATOR.PACKAGE_INFO',
    value: 'Pakuotės informacija',
  },
  {
    alias: 'PACKAGE_CALCULATOR.ADD_PACKAGE',
    value: 'Pridėti kitą siuntą',
  },
  {
    alias: 'PACKAGE_CALCULATOR.CALCULATE_AND_ORDER',
    value: 'Skaičiuoti ir užsakyti',
  },
  {
    alias: 'DELIVERY_CONTAINER.PICK_UP_FROM',
    value: 'Paėmimas iš',
  },
  {
    alias: 'DELIVERY_CONTAINER.COUNTRY',
    value: 'Šalis',
  },
  {
    alias: 'DELIVERY_CONTAINER.POST_CODE',
    value: 'Pašto kodas',
  },
  {
    alias: 'DELIVERY_CONTAINER.DELIVERY_TO',
    value: 'Pristatymas į',
  },
  {
    alias: 'SHIPMENT.WEIGHT',
    value: 'Svoris',
  },
  {
    alias: 'SHIPMENT.WIDTH',
    value: 'Plotis',
  },
  {
    alias: 'SHIPMENT.LENGTH',
    value: 'Ilgis',
  },
  {
    alias: 'SHIPMENT.HEIGHT',
    value: 'Aukštis',
  },
  {
    alias: 'SHIPMENT_TYPE.LETTER',
    value: 'Vokas',
  },
  {
    alias: 'SHIPMENT_TYPE.BOX',
    value: 'Dėžė',
  },
  {
    alias: 'SHIPMENT_TYPE.DOCUMENTS',
    value: 'Dokumentai',
  },
  {
    alias: 'SHIPMENT_TYPE.PALLET',
    value: 'Paletė',
  },
  {
    alias: 'ACCOUNT_FORM.NAME',
    value: 'Vardas',
  },
  {
    alias: 'ACCOUNT_FORM.LAST_NAME',
    value: 'Pavardė',
  },
  {
    alias: 'ACCOUNT_FORM.EMAIL',
    value: 'El. paštas',
  },
  {
    alias: 'ACCOUNT_FORM.ADDRESS',
    value: 'Adresas',
  },
  {
    alias: 'ACCOUNT_FORM.CITY',
    value: 'Miestas',
  },
  {
    alias: 'ACCOUNT_FORM.POST_CODE',
    value: 'Pašto kodas',
  },
  {
    alias: 'ACCOUNT_FORM.COUNTRY',
    value: 'Šalis',
  },
  {
    alias: 'ACCOUNT_FORM.PASSWORD',
    value: 'Slaptažodis',
  },
  {
    alias: 'ACCOUNT_FORM.COMPANY',
    value: 'Įmonė',
  },
  {
    alias: 'ACCOUNT_FORM.COMPANY_CODE',
    value: 'Įmonės kodas',
  },
  {
    alias: 'ACCOUNT_FORM.VAT_NUMBER',
    value: 'PVM mokėtojo kodas',
  },
  {
    alias: 'ACCOUNT_FORM.INVOICE_MODE',
    value: 'Sąskaitą faktūrą išrašyti',
  },
  {
    alias: 'ACCOUNT_FORM.INVOICE_MODE_DAY',
    value: 'Dienos pabaigoje',
  },
  {
    alias: 'ACCOUNT_FORM.NEWSLETTER',
    value: 'Pageidauju gauti informaciją apie „Packsender“ ir ' +
      'partnerių prekes ar paslaugas (el. paštu / SMS žinute / skambučiu)',
  },
  {
    alias: 'ACCOUNT_FORM.SAVE',
    value: 'Išsaugoti',
  },
  {
    alias: 'BASKET_ITEM.PICK_UP_FROM',
    value: 'Paėmimas iš',
  },
  {
    alias: 'BASKET_ITEM.DELIVERY_TO',
    value: 'Pristatymas į',
  },
  {
    alias: 'INVOICE_LIST.DATE',
    value: 'Data',
  },
  {
    alias: 'INVOICE_LIST.DELIVERY_NO',
    value: 'Pristatymo nr.',
  },
  {
    alias: 'INVOICE_LIST.PAYMENT_METHOD',
    value: 'Mokėjimo būdas',
  },
  {
    alias: 'INVOICE_LIST.AMOUNT',
    value: 'Suma',
  },
  {
    alias: 'ORDER_ITEM.DELIVERY_NO',
    value: 'Pristatymo nr.',
  },
  {
    alias: 'ORDER_ITEM.PICK_UP_DATE',
    value: 'Paėmimo data',
  },
  {
    alias: 'ORDER_ITEM.DELIVERY_DATE',
    value: 'Pristatymo data',
  },
  {
    alias: 'ORDER_ITEM.PICK_UP_FROM',
    value: 'Paėmimas iš',
  },
  {
    alias: 'ORDER_ITEM.DELIVERY_TO',
    value: 'Pristatymas į',
  },
  {
    alias: 'ORDER_ITEM.LABEL',
    value: 'Siuntos lipdukas',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_BASKET',
    value: 'Mano krepšelis',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_ORDERS',
    value: 'Mano užsakymai',
  },
  {
    alias: 'PROFILE_LAYOUT.SHIPMENTS_TRACKING',
    value: 'Siuntų sekimas',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_ACCOUNT',
    value: 'Mano duomenys',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_INVOICES',
    value: 'Mano sąskaitos',
  },
  {
    alias: 'PROFILE_LAYOUT.TOP_UP_ACCOUNT',
    value: 'Pildyti sąskaitą',
  },
  {
    alias: 'TRACKING_ITEM.DELIVERY_NO',
    value: 'Pristatymo nr.',
  },
  {
    alias: 'TRACKING_ITEM.PICK_UP_FROM',
    value: 'Paėmimas iš',
  },
  {
    alias: 'TRACKING_ITEM.DELIVERY_TO',
    value: 'Pristatymas į',
  },
  {
    alias: 'REGISTER.TITLE',
    value: 'Registruotis',
  },
  {
    alias: 'REGISTER.NAME',
    value: 'Vardas',
  },
  {
    alias: 'REGISTER.LAST_NAME',
    value: 'Pavardė',
  },
  {
    alias: 'REGISTER.EMAIL',
    value: 'El. paštas',
  },
  {
    alias: 'REGISTER.ADDRESS',
    value: 'Adresas',
  },
  {
    alias: 'REGISTER.CITY',
    value: 'Miestas',
  },
  {
    alias: 'REGISTER.POST_CODE',
    value: 'Pašto kodas',
  },
  {
    alias: 'REGISTER.COUNTRY',
    value: 'Šalis',
  },
  {
    alias: 'REGISTER.PASSWORD',
    value: 'Slaptažodis',
  },
  {
    alias: 'REGISTER.PASSWORD_REPEAT',
    value: 'Pakartokite slaptažodį',
  },
  {
    alias: 'REGISTER.COMPANY',
    value: 'Įmonė',
  },
  {
    alias: 'REGISTER.COMPANY_CODE',
    value: 'Įmonės kodas',
  },
  {
    alias: 'REGISTER.VAT_NUMBER',
    value: 'PVM mokėtojo kodas',
  },
  {
    alias: 'REGISTER.INVOICE_MODE',
    value: 'Sąskaitą faktūrą išrašyti',
  },
  {
    alias: 'REGISTER.INVOICE_MODE_DAY',
    value: 'Dienos pabaigoje',
  },
  {
    alias: 'REGISTER.NEWSLETTER',
    value: 'Pageidauju gauti informaciją apie „Packsender“ ir ' +
      'partnerių prekes ar paslaugas (el. paštu / SMS žinute / skambučiu)',
  },
  {
    alias: 'REGISTER.AGREEMENT',
    value: 'Su Terminais ir sąlygomis bei Privatumo politika susipažinau ir sutinku',
  },
  {
    alias: 'REGISTER.REGISTER',
    value: 'Registruotis',
  },
  {
    alias: 'REGISTER.HAVE_AN_ACCOUNT',
    value: 'Jau turite paskyrą?',
  },
  {
    alias: 'REGISTER.LOGIN',
    value: 'Prisijunkite',
  },
  {
    alias: 'TRY_CALCULATOR.TITLE',
    value: 'Išbandykite skaičiuoklę',
  },
  {
    alias: 'TRY_CALCULATOR.CALCULATOR',
    value: 'Skaičiuoklė',
  },
  {
    alias: 'ABOUT_US.TITLE',
    value: 'Apie mus',
  },
  {
    alias: 'ABOUT_US.OUR_STORY',
    value: 'Mūsų istorija',
  },
  {
    alias: 'ABOUT_US.OUR_GOAL',
    value: 'Mūsų tikslas',
  },
  {
    alias: 'BUSINESS.TITLE',
    value: 'Verslui',
  },
  {
    alias: 'EUROPEAN_DONATION.TITLE',
    value: 'ES projektai',
  },
  {
    alias: 'EUROPEAN_DONATION.INFO_TITLE',
    value: 'MB „Packsender” įgyvendina projektą',
  },
  {
    alias: 'EUROPEAN_DONATION.INFO_CONTENT',
    value: '<p>Automatizuota logistikos maršrutų ir optimaliausių pasiūlymų generavimo sistema, paremta ' +
      'evoliuciniu dirbtinio intelekto algoritmu.</p>' +
      '<p>Projekto tikslas – sukurti automatizuotą logistikos paslaugų paiešką vykdančią sistemą, kuri apdoroja ' +
      'visame internete pasiekiamą maršrutų informaciją, sukurdama optimalius kelionės maršrutus bei visų kelionių ' +
      'indeksą, kurio pagrindu būtų galima sklandžiai vykdyti automatizuotą transporto paslaugų paiešką ir ' +
      'užsakymą, formuluojant pervežimo užsakymą – nuo taško A į tašką B, formatu. Šios sistemos pagrindu ' +
      'siekiama sumažinti nereikalingų, verslui neefektyvių pervežimų kiekį, prisidedant prie nereikalingai ' +
      'išmetamo į atmosferą CO2 mažinimo, tuo pat metu gerinant viso pasaulio prekybos verslo ' +
      'efektyvumą – suteikiant daugiau logistikos pasiūlos elastiškumo visai rinkai.</p>' +
      '<p>Įgyvendinus projektą bus sukurta centralizuota internetinė platforma, leidžianti nusipirkti logistikos ' +
      'paslaugas iš taško A į tašką B. Ši platforma veiks sukurtų integracijų principu tarp transporto biržų ' +
      'ir kitų logistikos tiekėjų. Visas produktas, kaip centralizuota logistikos pasiūlymų ' +
      'sistema - susidės iš šių prototipų:</p>' +
      '<p>- Transporto paslaugų paieškos ir pardavimų sistema.</p>' +
      '<p>- Integracijų su transporto biržomis ir kitais tiekėjais sistema.</p>' +
      '<p>- Skruzdžių kolonijos dirbtinio intelekto modulio, optimizuojančios kelių paiešką.</p>' +
      '<p>Projektas įgyvendinamas pagal 2014–2020 m. Europos Sąjungos fondų investicijų veiksmų programos 1 ' +
      'prioriteto „Mokslinių tyrimų, eksperimentinės plėtros ir inovacijų skatinimas“ įgyvendinimo ' +
      'priemonę Nr. 01.2.1-LVPA-K-856 „Eksperimentas“.</p>' +
      '<p>Projektas finansuojamas iš Europos Sąjungos struktūrinių fondų lėšų.</p>' +
      '<p>Projekto įgyvendinimo trukmė – 24 mėn.</p>',
  },
  {
    alias: 'CALCULATE.TITLE',
    value: 'Skaičiuoklė',
  },
  {
    alias: 'CONFIRMATION.TITLE',
    value: 'Siuntos siuntimas',
  },
  {
    alias: 'DETAILS.TITLE',
    value: 'Siuntos siuntimas',
  },
  {
    alias: 'SERVICE.TITLE',
    value: 'Siuntos siuntimas',
  },
  {
    alias: 'PAYMENT.TITLE',
    value: 'Siuntos siuntimas',
  },
  {
    alias: 'CONTACT.TITLE',
    value: 'Kontaktai',
  },
  {
    alias: 'FAQ.TITLE',
    value: 'D.U.K',
  },
  {
    alias: 'HOMEPAGE.ABOUT_US',
    value: 'Kas mes',
  },
  {
    alias: 'ACCOUNT.TITLE',
    value: 'Mano paskyra',
  },
  {
    alias: 'BASKET.TITLE',
    value: 'Mano krepšelis',
  },
  {
    alias: 'BASKET.NO_RESULTS_TITLE',
    value: 'Jūsų krepšelis tuščias',
  },
  {
    alias: 'BASKET.NO_RESULTS_SUBTITLE',
    value: 'Jūsų krepšelyje nėra nei vienos užsakytos siuntos',
  },
  {
    alias: 'BASKET.NO_RESULTS_DESCRIPTION',
    value: 'Norite išsiųsti siuntą? Išbandykite skaičiuoklę:',
  },
  {
    alias: 'BASKET.NO_RESULTS_BUTTON',
    value: 'Skaičiuoklė',
  },
  {
    alias: 'INVOICES.TITLE',
    value: 'Mano sąskaitos',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_TITLE',
    value: 'Jūsų sąskaitų sąrašas tuščias',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_SUBTITLE',
    value: 'Jūs neturite nei vienos sąskaitos',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_DESCRIPTION',
    value: 'Norite išsiųsti siuntą? Išbandykite skaičiuoklę:',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_BUTTON',
    value: 'Skaičiuoklė',
  },
  {
    alias: 'ORDERS.TITLE',
    value: 'Mano užsakymai',
  },
  {
    alias: 'ORDERS.NO_RESULTS_TITLE',
    value: 'Jūsų užsakymų sąrašas tuščias',
  },
  {
    alias: 'ORDERS.NO_RESULTS_SUBTITLE',
    value: 'Jūs neturite nei vienos užsakytos siuntos',
  },
  {
    alias: 'ORDERS.NO_RESULTS_DESCRIPTION',
    value: 'Norite išsiųsti siuntą? Išbandykite skaičiuoklę:',
  },
  {
    alias: 'ORDERS.NO_RESULTS_BUTTON',
    value: 'Skaičiuoklė',
  },
  {
    alias: 'TRACKING.TITLE',
    value: 'Siuntų sekimas',
  },
  {
    alias: 'TRACKING.NO_RESULTS_TITLE',
    value: 'Jūsų užsakymų sąrašas tuščias',
  },
  {
    alias: 'TRACKING.NO_RESULTS_SUBTITLE',
    value: 'Jūs neturite nei vienos užsakytos siuntos',
  },
  {
    alias: 'TRACKING.NO_RESULTS_DESCRIPTION',
    value: 'Norite išsiųsti siuntą? Išbandykite skaičiuoklę:',
  },
  {
    alias: 'TRACKING.NO_RESULTS_BUTTON',
    value: 'Skaičiuoklė',
  },
  {
    alias: 'ORDER_STATUS.PAID',
    value: 'Apmokėta',
  },
  {
    alias: 'ORDER_STATUS.DELIVERED',
    value: 'Pristatyta',
  },
  {
    alias: 'ORDER_STATUS.IN_PROGRESS',
    value: 'Vykdoma',
  },
  {
    alias: 'BALANCE.TITLE',
    value: 'Pildyti sąskaitą',
  },
  {
    alias: 'BALANCE_INFO.BALANCE',
    value: 'Balansas',
  },
  {
    alias: 'TOP_UP_FORM.AMOUNT_LABEL',
    value: 'Papildymo suma €',
  },
  {
    alias: 'TOP_UP_FORM.AMOUNT_PLACEHOLDER',
    value: 'Įveskite papildymo sumą',
  },
  {
    alias: 'TOP_UP_FORM.CARD_NUMBER',
    value: 'Kortelės numeris',
  },
  {
    alias: 'TOP_UP_FORM.CARD_EXPIRY',
    value: 'Galiojimo data',
  },
  {
    alias: 'TOP_UP_FORM.CARD_CVC',
    value: 'CVC',
  },
  {
    alias: 'TOP_UP_FORM.SUBMIT',
    value: 'Patvirtinti papildymą',
  },
  {
    alias: 'BALANCE_LIST.DATE',
    value: 'Data',
  },
  {
    alias: 'BALANCE_LIST.AMOUNT',
    value: 'Suma',
  },
  {
    alias: 'HOMEPAGE.ABOUT_US_CONTENT',
    value: 'Mes esame informacinių technologijų komanda siekianti patobulinti mūsų kasdieninį gyvenimą. ' +
      'Naudodami jau sukurtą skaičiavimo algoritmą, vystome mokslinį tyrimą. Projekto tikslas - naudojant ' +
      'algoritmą, gebantį apskaičiuoti kelionės trumpiausią ir optimaliausią kelią, siuntos ' +
      'įgyvendinimui.\n\nŠio projekto įgyvendinimo metu, bus kuriama transporto paslaugas ' +
      'parduodanti internetinė sistema.',
  },
  {
    alias: 'BUSINESS.INFO_TITLE',
    value: 'Logistikos įmonėms',
  },
  {
    alias: 'BUSINESS.INFO_CONTENT',
    value: 'Jei jūs esate įmonė dirbanti su savo asmeninėmis fūromis, krovininiais ' +
      'automobiliais, naudojantės įvairiausių žemėlapių, ar telematikos sistemomis, ' +
      'šis pasiūlymas yra jums. Mūsų kuriama sistema skaičiuoja ne tik ' +
      'pigiausią kelionę, bet ir nagrinėja tinkamiausius kelionės maršrutus, kurie ' +
      'taupo kelio, laiko ir kuro sąnaudas. Susisiekite su mumis ir mes ' +
      'padėsime jums apskaičiuoti jūsų logistikos sąnaudų sutaupymo galimybę.',
  },
  {
    alias: 'BUSINESS_CONTENT.TITLE',
    value: 'Packsender - geriausių pasiūlymų generavimo sistema, veikianti vartotojams ir paslaugos tiekėjams',
  },
  {
    alias: 'BUSINESS_CONTENT.INFO_TITLE',
    value: '“Drop shipping” įmonėms',
  },
  {
    alias: 'BUSINESS_CONTENT.INFO_CONTENT',
    value: 'Ši sukurta sistema pukiai padės “dropshipping” modėliu dirbančioms įmonėms prižiūrėti bei ' +
      'valdyti savo siunčiamas prekes po visą pasaulį. Tikslas - ne tik ' +
      'atrasti pigesnę galimybę nusiųsti jūsų siuntą nuo taško A iki taško ' +
      'B, o suteikti patogų įrankį valdyti siuntas, net jeigu jos bus ' +
      'siunčiamos tiesiai nuo gamintojo iki kliento durų. Dėl galimų ' +
      'integracijų su vietinėmis sistemomis, prašome susisiekti.',
  },
  {
    alias: 'ABOUT_US.OUR_STORY_CONTENT',
    value: 'Mes esame informacinių technologijų komanda siekianti patobulinti mūsų kasdieninį gyvenimą. ' +
      'Naudodami jau sukurtą skaičiavimo algoritmą, vystome mokslinį tyrimą. Projekto tikslas - naudojant ' +
      'algoritmą, gebantį apskaičiuoti kelionės trumpiausią ir optimaliausią kelią, ' +
      'siuntos įgyvendinimui.\n\nŠio projekto įgyvendinimo metu, bus kuriama transporto paslaugas ' +
      'parduodanti internetinė sistema.',
  },
  {
    alias: 'CALCULATE_CONTENT.TITLE',
    value: 'Packsender - geriausių pasiūlymų generavimo sistema, veikianti vartotojams ir paslaugos tiekėjams',
  },
  {
    alias: 'CALCULATE_CONTENT.INFO_TITLE',
    value: 'Logistikos įmonėms',
  },
  {
    alias: 'CALCULATE_CONTENT.INFO_CONTENT',
    value: 'Jei jūs esate įmonė dirbanti su savo asmeninėmis fūromis, krovininiais ' +
      'automobiliais, naudojantės įvairiausių žemėlapių, ar telematikos sistemomis, ' +
      'šis pasiūlymas yra jums. Mūsų kuriama sistema skaičiuoja ne tik ' +
      'pigiausią kelionę, bet ir nagrinėja tinkamiausius kelionės maršrutus, kurie ' +
      'taupo kelio, laiko ir kuro sąnaudas. Susisiekite su mumis ir mes ' +
      'padėsime jums apskaičiuoti jūsų logistikos sąnaudų sutaupymo galimybę.',
  },
  {
    alias: 'PAYMENT.USE_BALANCE',
    value: 'Naudoti balansą',
  },
  {
    alias: 'PAYMENT.NOT_ENOUGH_BALANCE',
    value: 'Jūsų balanso likutis nepakankamas. Spauskite čia norėdami papildyti balansą',
  },
];

export const translationsEN: Translation[] = [
  {
    alias: 'VALIDATION.REQUIRED',
    value: 'Required field',
  },
  {
    alias: 'VALIDATION.EMAIL',
    value: 'Entered email address is not allowed',
  },
  {
    alias: 'VALIDATION.PASSWORDS_DO_NOT_MATCH',
    value: 'Passwords are not the same',
  },
  {
    alias: 'FOOTER.COPYRIGHT',
    value: '© All rights reserved 2022',
  },
  {
    alias: 'NAVIGATION.ABOUT_US',
    value: 'About us',
  },
  {
    alias: 'NAVIGATION.BUSINESS',
    value: 'Business',
  },
  {
    alias: 'NAVIGATION.FAQ',
    value: 'FAQ',
  },
  {
    alias: 'NAVIGATION.CONTACTS',
    value: 'Contacts',
  },
  {
    alias: 'NAVIGATION.MY_ACCOUNT',
    value: 'My account',
  },
  {
    alias: 'NAVIGATION.LOGOUT',
    value: 'Logout',
  },
  {
    alias: 'NAVIGATION.LOGIN',
    value: 'Login',
  },
  {
    alias: 'NAVIGATION.REGISTER',
    value: 'Register',
  },
  {
    alias: 'NAVIGATION.EUROPEAN_UNION_PROJECTS',
    value: 'EU Projects',
  },
  {
    alias: 'ADDRESS_BLOCK.SENDER',
    value: 'Sender (person)',
  },
  {
    alias: 'ADDRESS_BLOCK.PHONE',
    value: 'Phone',
  },
  {
    alias: 'ADDRESS_BLOCK.COUNTRY',
    value: 'Country',
  },
  {
    alias: 'ADDRESS_BLOCK.ADDRESS',
    value: 'Address',
  },
  {
    alias: 'ADDRESS_BLOCK.CITY',
    value: 'City/town name',
  },
  {
    alias: 'ADDRESS_BLOCK.POST_CODE',
    value: 'Post code',
  },
  {
    alias: 'ADDRESS_FORM.NAME',
    value: 'Name',
  },
  {
    alias: 'ADDRESS_FORM.LAST_NAME',
    value: 'Last name',
  },
  {
    alias: 'ADDRESS_FORM.ADDRESS',
    value: 'Address',
  },
  {
    alias: 'ADDRESS_FORM.CITY',
    value: 'City',
  },
  {
    alias: 'ADDRESS_FORM.REGION',
    value: 'Region',
  },
  {
    alias: 'ADDRESS_FORM.POST_CODE',
    value: 'Post code',
  },
  {
    alias: 'ADDRESS_FORM.PHONE',
    value: 'Phone number',
  },
  {
    alias: 'ADDRESS_FORM.EMAIL',
    value: 'Email',
  },
  {
    alias: 'ADDRESS_FORM.COUNTRY',
    value: 'Country',
  },
  {
    alias: 'ADDRESS_FORM.UPDATE',
    value: 'Update',
  },
  {
    alias: 'ADDRESS_FORM.CREATE',
    value: 'Create',
  },
  {
    alias: 'ADDRESS_FORM.CANCEL',
    value: 'Cancel',
  },
  {
    alias: 'ADDRESS_ITEM.SELECT',
    value: 'Select',
  },
  {
    alias: 'ADDRESS_ITEM.EDIT',
    value: 'Edit',
  },
  {
    alias: 'ADDRESS_ITEM.DELETE',
    value: 'Delete',
  },
  {
    alias: 'CONFIRMATION.PICK_UP_ADDRESS',
    value: 'Pick up address',
  },
  {
    alias: 'CONFIRMATION.ADD_ADDRESS',
    value: 'Add address',
  },
  {
    alias: 'CONFIRMATION.DELIVERY_ADDRESS',
    value: 'Delivery address',
  },
  {
    alias: 'CONFIRMATION.SHIPMENT_DATA',
    value: 'Shipment data',
  },
  {
    alias: 'CONFIRMATION.TOTAL',
    value: 'Total',
  },
  {
    alias: 'CONFIRMATION.FROM',
    value: 'From',
  },
  {
    alias: 'CONFIRMATION.TO',
    value: 'To',
  },
  {
    alias: 'CONFIRMATION.AVERAGE_DELIVERY',
    value: 'Delivery takes an average time of',
  },
  {
    alias: 'CONFIRMATION.CONFIRM',
    value: 'Confirm',
  },
  {
    alias: 'DETAILS.SHIPMENT_DETAILS',
    value: 'Shipment details',
  },
  {
    alias: 'DETAILS.PICK_UP_FROM',
    value: 'Pick up from',
  },
  {
    alias: 'DETAILS.DELIVERY_TO',
    value: 'Delivery to',
  },
  {
    alias: 'DETAILS.SERVICE',
    value: 'Service',
  },
  {
    alias: 'DETAILS.COURIER',
    value: 'Courier',
  },
  {
    alias: 'DETAILS.PICK_UP',
    value: 'Pick up',
  },
  {
    alias: 'DETAILS.DELIVERY',
    value: 'Delivery',
  },
  {
    alias: 'DETAILS.PRICE',
    value: 'Price',
  },
  {
    alias: 'DETAILS.VAT',
    value: 'VAT',
  },
  {
    alias: 'DETAILS.CONFIRM',
    value: 'Confirm',
  },
  {
    alias: 'DETAILS_BLOCK.EDIT_DATA',
    value: 'Edit data',
  },
  {
    alias: 'PROGRESS_BAR.SERVICE_SELECTION',
    value: 'Service selection',
  },
  {
    alias: 'PROGRESS_BAR.SHIPMENT_DETAILS',
    value: 'Shipment details',
  },
  {
    alias: 'PROGRESS_BAR.ORDER_CONFIRMATION',
    value: 'Order confirmation',
  },
  {
    alias: 'PROGRESS_BAR.PAYMENT',
    value: 'Payment',
  },
  {
    alias: 'SERVICE.DELIVERY',
    value: 'Delivery',
  },
  {
    alias: 'SERVICE.SELECT',
    value: 'Select',
  },
  {
    alias: 'SERVICE.NO_SERVICES',
    value: 'No available services were found',
  },
  {
    alias: 'SINGLE_SERVICE.VAT',
    value: 'VAT',
  },
  {
    alias: 'INFO_BLOCK.CONTACT_US',
    value: 'Contact us',
  },
  {
    alias: 'CONTACT.CONTACT_US',
    value: 'Contact us',
  },
  {
    alias: 'CONTACT.NAME',
    value: 'Name',
  },
  {
    alias: 'CONTACT.EMAIL',
    value: 'Email',
  },
  {
    alias: 'CONTACT.PHONE',
    value: 'Phone number',
  },
  {
    alias: 'CONTACT.MESSAGE',
    value: 'Message',
  },
  {
    alias: 'CONTACT.SEND_MESSAGE',
    value: 'Send message',
  },
  {
    alias: 'CONTACT_CONTENT.COMPANY_CODE',
    value: 'Company code',
  },
  {
    alias: 'FAQ_CONTENT.ENTER_QUESTION',
    value: 'Enter question',
  },
  {
    alias: 'FAQ_CONTENT.SUBTITLE',
    value: 'Select a category to quickly find an answer',
  },
  {
    alias: 'HEADER.CONTENT_TEXT',
    value: 'System for generating logistics routes and optimal offers',
  },
  {
    alias: 'HEADER.MORE',
    value: 'More',
  },
  {
    alias: 'HOW_IT_WORKS.CALCULATE_PRICE_TITLE',
    value: 'Calculate price',
  },
  {
    alias: 'HOW_IT_WORKS.CALCULATE_PRICE_DESCRIPTION',
    value: 'Enter your and the recipient\'s zip codes, select the package type and enter ' +
      'the dimensions. According to the size of the package and the delivery address, you will be provided ' +
      'with courier offers and the price of the service.',
  },
  {
    alias: 'HOW_IT_WORKS.PLACE_ORDER_TITLE',
    value: 'Place order',
  },
  {
    alias: 'HOW_IT_WORKS.PLACE_ORDER_DESCRIPTION',
    value: 'Choose the offer that suits you best, order the parcel delivery service, and, if ' +
      'necessary, additional services - additional parcel insurance, door-to-door ' +
      'delivery service, etc.',
  },
  {
    alias: 'HOW_IT_WORKS.ATTACH_DOCUMENTS_TITLE',
    value: 'Attach documents',
  },
  {
    alias: 'HOW_IT_WORKS.ATTACH_DOCUMENTS_DESCRIPTION',
    value: 'Within 15-20 minutes from the payment of the order, you will receive an email with ' +
      'shipping documents. Be sure to print them and attach them in a visible ' +
      'place on the package that is properly prepared for the trip.',
  },
  {
    alias: 'HOW_IT_WORKS.GIVE_PACKAGE_TITLE',
    value: 'Give package to courier',
  },
  {
    alias: 'HOW_IT_WORKS.GIVE_PACKAGE_DESCRIPTION',
    value: 'Hand the parcel ready for delivery to the courier who has come to pick it up or ' +
      'deliver it to the nearest parcel collection point.',
  },
  {
    alias: 'HOW_IT_WORKS.HOW_IT_WORKS',
    value: 'How it works',
  },
  {
    alias: 'LOGIN.TITLE',
    value: 'Login',
  },
  {
    alias: 'LOGIN.INCORRECT_LOGIN_DATA',
    value: 'Incorrect login data',
  },
  {
    alias: 'LOGIN.FIELD_EMAIL',
    value: 'Email',
  },
  {
    alias: 'LOGIN.PLACEHOLDER_EMAIL',
    value: 'Enter email',
  },
  {
    alias: 'LOGIN.FIELD_PASSWORD',
    value: 'Password',
  },
  {
    alias: 'LOGIN.PLACEHOLDER_PASSWORD',
    value: 'Enter password',
  },
  {
    alias: 'LOGIN.LOGIN',
    value: 'Login',
  },
  {
    alias: 'LOGIN.NO_ACCOUNT',
    value: 'Don\'t have an account?',
  },
  {
    alias: 'LOGIN.REGISTER',
    value: 'Register',
  },
  {
    alias: 'NOT_FOUND.PAGE_NOT_FOUND',
    value: 'Page not found',
  },
  {
    alias: 'NOT_FOUND.PAGE_NOT_FOUND_CONTENT',
    value: 'The page you are looking for is not available at the moment, is ' +
      'deleted or the link has changed.',
  },
  {
    alias: 'NOT_FOUND.GO_BACK_TO_HOMEPAGE',
    value: 'Go back to homepage',
  },
  {
    alias: 'PACKAGE_CALCULATOR.TITLE',
    value: 'Shipment calculator',
  },
  {
    alias: 'PACKAGE_CALCULATOR.PACKAGE_INFO',
    value: 'Package information',
  },
  {
    alias: 'PACKAGE_CALCULATOR.ADD_PACKAGE',
    value: 'Add additional package',
  },
  {
    alias: 'PACKAGE_CALCULATOR.CALCULATE_AND_ORDER',
    value: 'Calculate and order',
  },
  {
    alias: 'DELIVERY_CONTAINER.PICK_UP_FROM',
    value: 'Pick up from',
  },
  {
    alias: 'DELIVERY_CONTAINER.COUNTRY',
    value: 'Country',
  },
  {
    alias: 'DELIVERY_CONTAINER.POST_CODE',
    value: 'Post code',
  },
  {
    alias: 'DELIVERY_CONTAINER.DELIVERY_TO',
    value: 'Delivery to',
  },
  {
    alias: 'SHIPMENT.WEIGHT',
    value: 'Weight',
  },
  {
    alias: 'SHIPMENT.WIDTH',
    value: 'Width',
  },
  {
    alias: 'SHIPMENT.LENGTH',
    value: 'Length',
  },
  {
    alias: 'SHIPMENT.HEIGHT',
    value: 'Height',
  },
  {
    alias: 'SHIPMENT_TYPE.LETTER',
    value: 'Letter',
  },
  {
    alias: 'SHIPMENT_TYPE.BOX',
    value: 'Box',
  },
  {
    alias: 'SHIPMENT_TYPE.DOCUMENTS',
    value: 'Documents',
  },
  {
    alias: 'SHIPMENT_TYPE.PALLET',
    value: 'Pallet',
  },
  {
    alias: 'ACCOUNT_FORM.NAME',
    value: 'Name',
  },
  {
    alias: 'ACCOUNT_FORM.LAST_NAME',
    value: 'Last name',
  },
  {
    alias: 'ACCOUNT_FORM.EMAIL',
    value: 'Email',
  },
  {
    alias: 'ACCOUNT_FORM.ADDRESS',
    value: 'Address',
  },
  {
    alias: 'ACCOUNT_FORM.CITY',
    value: 'City',
  },
  {
    alias: 'ACCOUNT_FORM.POST_CODE',
    value: 'Post code',
  },
  {
    alias: 'ACCOUNT_FORM.COUNTRY',
    value: 'Country',
  },
  {
    alias: 'ACCOUNT_FORM.PASSWORD',
    value: 'Password',
  },
  {
    alias: 'ACCOUNT_FORM.COMPANY',
    value: 'Company',
  },
  {
    alias: 'ACCOUNT_FORM.COMPANY_CODE',
    value: 'Company code',
  },
  {
    alias: 'ACCOUNT_FORM.VAT_NUMBER',
    value: 'VAT number',
  },
  {
    alias: 'ACCOUNT_FORM.INVOICE_MODE',
    value: 'Generate invoice',
  },
  {
    alias: 'ACCOUNT_FORM.INVOICE_MODE_DAY',
    value: 'At the end of the day',
  },
  {
    alias: 'ACCOUNT_FORM.NEWSLETTER',
    value: 'I would like to receive information about the goods ' +
      'or services of Packsender and partners (by e-mail / SMS / call)',
  },
  {
    alias: 'ACCOUNT_FORM.SAVE',
    value: 'Save',
  },
  {
    alias: 'BASKET_ITEM.PICK_UP_FROM',
    value: 'Pick up from',
  },
  {
    alias: 'BASKET_ITEM.DELIVERY_TO',
    value: 'Delivery to',
  },
  {
    alias: 'INVOICE_LIST.DATE',
    value: 'Date',
  },
  {
    alias: 'INVOICE_LIST.DELIVERY_NO',
    value: 'Delivery no.',
  },
  {
    alias: 'INVOICE_LIST.PAYMENT_METHOD',
    value: 'Payment method',
  },
  {
    alias: 'INVOICE_LIST.AMOUNT',
    value: 'Total amount',
  },
  {
    alias: 'ORDER_ITEM.DELIVERY_NO',
    value: 'Delivery no.',
  },
  {
    alias: 'ORDER_ITEM.PICK_UP_DATE',
    value: 'Pick up date',
  },
  {
    alias: 'ORDER_ITEM.DELIVERY_DATE',
    value: 'Delivery date',
  },
  {
    alias: 'ORDER_ITEM.PICK_UP_FROM',
    value: 'Pick up from',
  },
  {
    alias: 'ORDER_ITEM.DELIVERY_TO',
    value: 'Delivery to',
  },
  {
    alias: 'ORDER_ITEM.LABEL',
    value: 'Shipment label',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_BASKET',
    value: 'My basket',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_ORDERS',
    value: 'My orders',
  },
  {
    alias: 'PROFILE_LAYOUT.SHIPMENTS_TRACKING',
    value: 'Shipments tracking',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_ACCOUNT',
    value: 'My account',
  },
  {
    alias: 'PROFILE_LAYOUT.MY_INVOICES',
    value: 'My invoices',
  },
  {
    alias: 'PROFILE_LAYOUT.TOP_UP_ACCOUNT',
    value: 'Top up account',
  },
  {
    alias: 'TRACKING_ITEM.DELIVERY_NO',
    value: 'Delivery no.',
  },
  {
    alias: 'TRACKING_ITEM.PICK_UP_FROM',
    value: 'Pick up from',
  },
  {
    alias: 'TRACKING_ITEM.DELIVERY_TO',
    value: 'Delivery to',
  },
  {
    alias: 'REGISTER.TITLE',
    value: 'Register',
  },
  {
    alias: 'REGISTER.NAME',
    value: 'Name',
  },
  {
    alias: 'REGISTER.LAST_NAME',
    value: 'Last name',
  },
  {
    alias: 'REGISTER.EMAIL',
    value: 'Email',
  },
  {
    alias: 'REGISTER.ADDRESS',
    value: 'Address',
  },
  {
    alias: 'REGISTER.CITY',
    value: 'City',
  },
  {
    alias: 'REGISTER.POST_CODE',
    value: 'Post code',
  },
  {
    alias: 'REGISTER.COUNTRY',
    value: 'Country',
  },
  {
    alias: 'REGISTER.PASSWORD',
    value: 'Password',
  },
  {
    alias: 'REGISTER.PASSWORD_REPEAT',
    value: 'Repeat password',
  },
  {
    alias: 'REGISTER.COMPANY',
    value: 'Company',
  },
  {
    alias: 'REGISTER.COMPANY_CODE',
    value: 'Company code',
  },
  {
    alias: 'REGISTER.VAT_NUMBER',
    value: 'VAT number',
  },
  {
    alias: 'REGISTER.INVOICE_MODE',
    value: 'Generate invoice',
  },
  {
    alias: 'REGISTER.INVOICE_MODE_DAY',
    value: 'At the end of the day',
  },
  {
    alias: 'REGISTER.NEWSLETTER',
    value: 'I would like to receive information about the goods ' +
      'or services of Packsender and partners (by e-mail / SMS / call)',
  },
  {
    alias: 'REGISTER.AGREEMENT',
    value: 'I have read and accept terms and conditions and privacy policy',
  },
  {
    alias: 'REGISTER.REGISTER',
    value: 'Register',
  },
  {
    alias: 'REGISTER.HAVE_AN_ACCOUNT',
    value: 'Already have an account?',
  },
  {
    alias: 'REGISTER.LOGIN',
    value: 'Login',
  },
  {
    alias: 'TRY_CALCULATOR.TITLE',
    value: 'Try our best price and route calculator now!',
  },
  {
    alias: 'TRY_CALCULATOR.CALCULATOR',
    value: 'Calculator',
  },
  {
    alias: 'ABOUT_US.TITLE',
    value: 'Apie mus',
  },
  {
    alias: 'ABOUT_US.OUR_STORY',
    value: 'Our story',
  },
  {
    alias: 'ABOUT_US.OUR_GOAL',
    value: 'Our goal',
  },
  {
    alias: 'BUSINESS.TITLE',
    value: 'Business',
  },
  {
    alias: 'EUROPEAN_DONATION.TITLE',
    value: 'EU projects',
  },
  {
    alias: 'CALCULATE.TITLE',
    value: 'Calculator',
  },
  {
    alias: 'CONFIRMATION.TITLE',
    value: 'Sending the parcel',
  },
  {
    alias: 'DETAILS.TITLE',
    value: 'Sending the parcel',
  },
  {
    alias: 'SERVICE.TITLE',
    value: 'Sending the parcel',
  },
  {
    alias: 'PAYMENT.TITLE',
    value: 'Sending the parcel',
  },
  {
    alias: 'CONTACT.TITLE',
    value: 'Contacts',
  },
  {
    alias: 'FAQ.TITLE',
    value: 'FAQ',
  },
  {
    alias: 'HOMEPAGE.ABOUT_US',
    value: 'About us',
  },
  {
    alias: 'ACCOUNT.TITLE',
    value: 'My account',
  },
  {
    alias: 'BASKET.TITLE',
    value: 'My basket',
  },
  {
    alias: 'BASKET.NO_RESULTS_TITLE',
    value: 'Your basket is empty',
  },
  {
    alias: 'BASKET.NO_RESULTS_SUBTITLE',
    value: 'There are no orders in your basket',
  },
  {
    alias: 'BASKET.NO_RESULTS_DESCRIPTION',
    value: 'Do you want to send a package? Try out the calculator:',
  },
  {
    alias: 'BASKET.NO_RESULTS_BUTTON',
    value: 'Calculator',
  },
  {
    alias: 'INVOICES.TITLE',
    value: 'My invoices',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_TITLE',
    value: 'Your invoices list is empty',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_SUBTITLE',
    value: 'There are no invoices in your list',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_DESCRIPTION',
    value: 'Do you want to send a package? Try out the calculator:',
  },
  {
    alias: 'INVOICE_LIST.NO_RESULTS_BUTTON',
    value: 'Calculator',
  },
  {
    alias: 'ORDERS.TITLE',
    value: 'My orders',
  },
  {
    alias: 'ORDERS.NO_RESULTS_TITLE',
    value: 'Your orders list is empty',
  },
  {
    alias: 'ORDERS.NO_RESULTS_SUBTITLE',
    value: 'There are no orders in your list',
  },
  {
    alias: 'ORDERS.NO_RESULTS_DESCRIPTION',
    value: 'Do you want to send a package? Try out the calculator:',
  },
  {
    alias: 'ORDERS.NO_RESULTS_BUTTON',
    value: 'Calculator',
  },
  {
    alias: 'TRACKING.TITLE',
    value: 'Shipments tracking',
  },
  {
    alias: 'TRACKING.NO_RESULTS_TITLE',
    value: 'Your orders list is empty',
  },
  {
    alias: 'TRACKING.NO_RESULTS_SUBTITLE',
    value: 'There are no orders in your list',
  },
  {
    alias: 'TRACKING.NO_RESULTS_DESCRIPTION',
    value: 'Do you want to send a package? Try out the calculator:',
  },
  {
    alias: 'TRACKING.NO_RESULTS_BUTTON',
    value: 'Calculator',
  },
  {
    alias: 'ORDER_STATUS.PAID',
    value: 'Paid',
  },
  {
    alias: 'ORDER_STATUS.DELIVERED',
    value: 'Delivered',
  },
  {
    alias: 'ORDER_STATUS.IN_PROGRESS',
    value: 'In progress',
  },
  {
    alias: 'BALANCE.TITLE',
    value: 'Top up account',
  },
  {
    alias: 'BALANCE_INFO.BALANCE',
    value: 'Balance',
  },
  {
    alias: 'TOP_UP_FORM.AMOUNT_LABEL',
    value: 'Top up amount €',
  },
  {
    alias: 'TOP_UP_FORM.AMOUNT_PLACEHOLDER',
    value: 'Enter top up amount',
  },
  {
    alias: 'TOP_UP_FORM.CARD_NUMBER',
    value: 'Card number',
  },
  {
    alias: 'TOP_UP_FORM.CARD_EXPIRY',
    value: 'Expiry date',
  },
  {
    alias: 'TOP_UP_FORM.CARD_CVC',
    value: 'CVC',
  },
  {
    alias: 'TOP_UP_FORM.SUBMIT',
    value: 'Confirm top up',
  },
  {
    alias: 'BALANCE_LIST.DATE',
    value: 'Date',
  },
  {
    alias: 'BALANCE_LIST.AMOUNT',
    value: 'Amount',
  },
  {
    alias: 'HOMEPAGE.ABOUT_US_CONTENT',
    value: 'We are an information technology team dedicated to improving our daily lives. Using an already ' +
      'developed calculation algorithm, we develop a scientific study. The goal of the project is to use an ' +
      'algorithm capable of calculating the shortest and most optimal route for the delivery of ' +
      'the shipment. \n\nDuring the implementation of this project, an online system selling transport services ' +
      'will be developed.',
  },
  {
    alias: 'BUSINESS.INFO_TITLE',
    value: 'Logistics companies',
  },
  {
    alias: 'BUSINESS.INFO_CONTENT',
    value: 'If you are a company working with your own personal vans, trucks, using various maps ' +
      'or telematics systems, this offer is for you. The system we are developing ' +
      'not only calculates the cheapest trip, but also examines the most ' +
      'suitable travel routes that save travel, time and fuel costs. Contact ' +
      'us and we will help you calculate your logistics cost savings.',
  },
  {
    alias: 'BUSINESS_CONTENT.TITLE',
    value: 'Packsender - the system for generating the best offers for users and service providers',
  },
  {
    alias: 'BUSINESS_CONTENT.INFO_TITLE',
    value: 'For “Drop shipping” companies',
  },
  {
    alias: 'BUSINESS_CONTENT.INFO_CONTENT',
    value: 'This developed system will perfectly help companies working on the "dropshipping" ' +
      'model to monitor and manage their shipped goods all over the world. The ' +
      'goal is not only to find a cheaper way to send your shipment from ' +
      'point A to point B, but to provide a convenient tool to manage ' +
      'shipments, even if they will be sent directly from the manufacturer ' +
      'to the customer\'s door. For possible integrations with local ' +
      'systems, please contact us.',
  },
  {
    alias: 'ABOUT_US.OUR_STORY_CONTENT',
    value: 'We are an information technology team dedicated to improving our daily lives. Using an already ' +
      'developed calculation algorithm, we develop a scientific study. The goal ' +
      'of the project is to use an algorithm capable of calculating the ' +
      'shortest and most optimal route for the delivery of the shipment. \n\nDuring the implementation of ' +
      'this project, an online system selling ' +
      'transport services will be developed.',
  },
  {
    alias: 'CALCULATE_CONTENT.TITLE',
    value: 'Packsender - the system for generating the best offers for users and service providers',
  },
  {
    alias: 'CALCULATE_CONTENT.INFO_TITLE',
    value: 'Logistics companies',
  },
  {
    alias: 'CALCULATE_CONTENT.INFO_CONTENT',
    value: 'If you are a company working with your own personal vans, trucks, using various maps ' +
      'or telematics systems, this offer is for you. The system we are developing ' +
      'not only calculates the cheapest trip, but also examines the most ' +
      'suitable travel routes that save travel, time and fuel costs. Contact ' +
      'us and we will help you calculate your logistics cost savings.',
  },
  {
    alias: 'PAYMENT.USE_BALANCE',
    value: 'Use balance',
  },
  {
    alias: 'PAYMENT.NOT_ENOUGH_BALANCE',
    value: 'Your balance is insufficient. Click here to top up your balance',
  },
];
