import * as actionTypes from './actionTypes';
import {CountryActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Country} from '../../domain/Country';

export type CountryStateType = {
  countries: Country[],
  getCountriesError: HttpError,
  getCountriesLoading: boolean,
};

export type CountryActionType = CountryStateType & {
  type: CountryActionTypes,
};

export const initialState: CountryStateType = {
  countries: [],
  getCountriesError: null,
  getCountriesLoading: false,
};

const getCountriesStart = (state: CountryStateType): CountryStateType => ({
  ...state,
  getCountriesLoading: true,
});

const getCountriesSuccess = (state: CountryStateType, action: CountryActionType): CountryStateType => ({
  ...state,
  getCountriesLoading: false,
  getCountriesError: null,
  countries: action.countries,
});

const getCountriesFail = (
  state: CountryStateType, action: CountryActionType,
): CountryStateType => ({
  ...state,
  getCountriesError: action.getCountriesError,
  getCountriesLoading: false,
});

const reducer = (state = initialState, action: CountryActionType) => {
  switch (action.type) {
  case actionTypes.GET_COUNTRIES_START:
    return getCountriesStart(state);
  case actionTypes.GET_COUNTRIES_SUCCESS:
    return getCountriesSuccess(state, action);
  case actionTypes.GET_COUNTRIES_FAIL:
    return getCountriesFail(state, action);
  default:
    return state;
  }
};

export default reducer;
