import axios from '../../config/Axios/axios-instance';
import {
  getCountriesFail,
  getCountriesStart,
  getCountriesSuccess,
} from './actions';
import {Dispatch} from 'redux';

const API_URL = '/v1/countries';

export const getCountries = () => (dispatch: Dispatch) => {
  dispatch(getCountriesStart());
  return axios.get(`${API_URL}`)
    .then(async (response) => {
      dispatch(getCountriesSuccess(response?.data?.data));
    })
    .catch((err) => {
      dispatch(getCountriesFail(err.response?.data?.message));
    })
  ;
};
