import * as actionTypes from './actionTypes';
import {BalanceActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {BalanceHistory} from '../../domain/Balance';

export type BalanceStateType = {
  balance: number,
  getBalanceError: HttpError,
  getBalanceLoading: boolean,
  balanceHistory: BalanceHistory[],
  getBalanceHistoryError: HttpError,
  getBalanceHistoryLoading: boolean,
  addBalanceError: HttpError,
  addBalanceLoading: boolean,
  addBalanceSuccess: boolean,
  useBalanceError: HttpError,
  useBalanceLoading: boolean,
  useBalanceSuccess: boolean,
};

export type BalanceActionType = BalanceStateType & {
  type: BalanceActionTypes,
};

export const initialState: BalanceStateType = {
  balance: 0,
  getBalanceError: null,
  getBalanceLoading: false,
  balanceHistory: [],
  getBalanceHistoryError: null,
  getBalanceHistoryLoading: false,
  addBalanceError: null,
  addBalanceLoading: false,
  addBalanceSuccess: false,
  useBalanceError: null,
  useBalanceLoading: false,
  useBalanceSuccess: false,
};

const getBalanceStart = (state: BalanceStateType): BalanceStateType => ({
  ...state,
  getBalanceLoading: true,
  addBalanceSuccess: false,
  useBalanceSuccess: false,
});

const getBalanceSuccess = (state: BalanceStateType, action: BalanceActionType): BalanceStateType => ({
  ...state,
  getBalanceLoading: false,
  getBalanceError: null,
  balance: action.balance,
});

const getBalanceFail = (
  state: BalanceStateType, action: BalanceActionType,
): BalanceStateType => ({
  ...state,
  getBalanceError: action.getBalanceError,
  getBalanceLoading: false,
});

const getBalanceHistoryStart = (state: BalanceStateType): BalanceStateType => ({
  ...state,
  getBalanceHistoryLoading: true,
});

const getBalanceHistorySuccess = (state: BalanceStateType, action: BalanceActionType): BalanceStateType => ({
  ...state,
  getBalanceHistoryLoading: false,
  getBalanceHistoryError: null,
  balanceHistory: action.balanceHistory,
});

const getBalanceHistoryFail = (
  state: BalanceStateType, action: BalanceActionType,
): BalanceStateType => ({
  ...state,
  getBalanceHistoryError: action.getBalanceHistoryError,
  getBalanceHistoryLoading: false,
});

const addBalanceStart = (state: BalanceStateType): BalanceStateType => ({
  ...state,
  addBalanceLoading: true,
  addBalanceSuccess: false,
});

const addBalanceSuccess = (state: BalanceStateType): BalanceStateType => ({
  ...state,
  addBalanceLoading: false,
  addBalanceError: null,
  addBalanceSuccess: true,
});

const addBalanceFail = (
  state: BalanceStateType, action: BalanceActionType,
): BalanceStateType => ({
  ...state,
  addBalanceError: action.addBalanceError,
  addBalanceLoading: false,
});

const useBalanceStart = (state: BalanceStateType): BalanceStateType => ({
  ...state,
  useBalanceLoading: true,
});

const useBalanceSuccess = (state: BalanceStateType): BalanceStateType => ({
  ...state,
  useBalanceLoading: false,
  useBalanceError: null,
  useBalanceSuccess: true,
});

const useBalanceFail = (
  state: BalanceStateType, action: BalanceActionType,
): BalanceStateType => ({
  ...state,
  useBalanceError: action.useBalanceError,
  useBalanceLoading: false,
});

const reducer = (state = initialState, action: BalanceActionType) => {
  switch (action.type) {
  case actionTypes.GET_BALANCE_START:
    return getBalanceStart(state);
  case actionTypes.GET_BALANCE_SUCCESS:
    return getBalanceSuccess(state, action);
  case actionTypes.GET_BALANCE_FAIL:
    return getBalanceFail(state, action);
  case actionTypes.GET_BALANCE_HISTORY_START:
    return getBalanceHistoryStart(state);
  case actionTypes.GET_BALANCE_HISTORY_SUCCESS:
    return getBalanceHistorySuccess(state, action);
  case actionTypes.GET_BALANCE_HISTORY_FAIL:
    return getBalanceHistoryFail(state, action);
  case actionTypes.ADD_BALANCE_START:
    return addBalanceStart(state);
  case actionTypes.ADD_BALANCE_SUCCESS:
    return addBalanceSuccess(state);
  case actionTypes.ADD_BALANCE_FAIL:
    return addBalanceFail(state, action);
  case actionTypes.USE_BALANCE_START:
    return useBalanceStart(state);
  case actionTypes.USE_BALANCE_SUCCESS:
    return useBalanceSuccess(state);
  case actionTypes.USE_BALANCE_FAIL:
    return useBalanceFail(state, action);
  default:
    return state;
  }
};

export default reducer;
