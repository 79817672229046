import * as actionTypes from './actionTypes';
import {AuthActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {User} from '../../domain/User';
import {Language} from '../../domain/Translation';

export type AuthStateType = {
  isAuthenticated: boolean,
  isInitCompleted: boolean,
  loginLoading: boolean,
  loginError: HttpError,
  registerLoading: boolean,
  registerError: HttpError,
  registerSuccess: boolean,
  user: null | User,
  getUserError: HttpError,
  getUserLoading: boolean,
  isLoginVisible: boolean,
  selectedLanguage: Language;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes,
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginLoading: false,
  loginError: null,
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
  user: null,
  getUserError: null,
  getUserLoading: false,
  isLoginVisible: false,
  selectedLanguage: localStorage.getItem('selectedLanguage') as Language ?? 'lt',
};

const loginStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginLoading: true,
  registerSuccess: false,
});

const loginSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginError: null,
  loginLoading: false,
});

const loginFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginError: action.loginError,
  loginLoading: false,
});

const registerStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerLoading: true,
  registerSuccess: false,
});

const registerSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerError: null,
  registerLoading: false,
  registerSuccess: true,
});

const registerFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerError: action.registerError,
  registerLoading: false,
});

const getUserStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  getUserLoading: true,
});

const getUserSuccess = (state: AuthStateType, action: AuthActionType): AuthStateType => ({
  ...state,
  getUserLoading: false,
  getUserError: null,
  user: action.user,
  isInitCompleted: true,
});

const getUserFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  getUserError: action.getUserError,
  getUserLoading: false,
  isInitCompleted: true,
});

const setIsLoginVisible = (state: AuthStateType, action: AuthActionType): AuthStateType => ({
  ...state,
  isLoginVisible: action.isLoginVisible,
});

const selectLanguage = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('selectedLanguage', action.selectedLanguage);

  return {
    ...state,
    selectedLanguage: action.selectedLanguage,
  };
};

const logout = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: false,
  isInitCompleted: true,
  loginLoading: false,
  loginError: null,
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
  user: null,
  getUserError: null,
  getUserLoading: false,
  isLoginVisible: false,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
  case actionTypes.LOGIN_START:
    return loginStart(state);
  case actionTypes.LOGIN_SUCCESS:
    return loginSuccess(state);
  case actionTypes.LOGIN_FAIL:
    return loginFail(state, action);
  case actionTypes.REGISTER_START:
    return registerStart(state);
  case actionTypes.REGISTER_SUCCESS:
    return registerSuccess(state);
  case actionTypes.REGISTER_FAIL:
    return registerFail(state, action);
  case actionTypes.GET_USER_START:
    return getUserStart(state);
  case actionTypes.GET_USER_SUCCESS:
    return getUserSuccess(state, action);
  case actionTypes.GET_USER_FAIL:
    return getUserFail(state, action);
  case actionTypes.SET_IS_LOGIN_VISIBLE:
    return setIsLoginVisible(state, action);
  case actionTypes.SELECT_LANGUAGE:
    return selectLanguage(state, action);
  case actionTypes.LOGOUT:
    return logout(state);
  default:
    return state;
  }
};

export default reducer;
