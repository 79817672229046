import React, {useState} from 'react';
import styles from './LanguageSwitcher.module.scss';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {selectLanguage} from '../../store/auth/actions';
import {StoreState} from '../../config/StoreProvider/StoreProvider';
import {Popover} from '@material-ui/core';
import {Language} from '../../domain/Translation';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import cx from 'classnames';

type Props = {
  isHomepage: boolean,
  onSelectLanguage: (language: Language) => void;
  selectedLanguage: string;
};

const LanguageSwitcher = ({onSelectLanguage, selectedLanguage, isHomepage}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const LANGUAGES = [
    {
      label: 'LT',
      value: 'lt',
    },
    {
      label: 'EN',
      value: 'en',
    },
  ];

  const selectedLanguageOption = LANGUAGES.find((language) => language.value === selectedLanguage);

  const handleClick = (language: Language) => {
    onSelectLanguage(language);
    setIsPopoverOpen(false);
  };

  const renderActions = () => {
    return LANGUAGES
      .map((language, index) => (
        <button
          className={styles.popoverListItem}
          key={index.toString()}
          onClick={() => handleClick(language.value as Language)}
        >
          {language?.label}
        </button>
      ));
  };

  return (
    <>
      <div
        className={cx(styles.switcherButton, {[styles.isHomepage]: isHomepage})}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {selectedLanguageOption?.label}
        <FontAwesomeIcon icon={faChevronDown} size="xs"/>
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>
            {renderActions()}
          </ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLanguage: state.auth.selectedLanguage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectLanguage: (language: Language) => dispatch(selectLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
