import * as actionTypes from './actionTypes';
import {ContactActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';

export type ContactStateType = {
  contactCreateLoading: boolean;
  contactCreateError: HttpError;
  contactCreateSuccess: boolean;
};

export type ContactActionType = ContactStateType & {
  type: ContactActionTypes;
};

export const initialState: ContactStateType = {
  contactCreateLoading: false,
  contactCreateError: null,
  contactCreateSuccess: false,
};

const createContactStart = (state: ContactStateType): ContactStateType => ({
  ...state,
  contactCreateLoading: true,
});

const createContactSuccess = (
  state: ContactStateType,
): ContactStateType => ({
  ...state,
  contactCreateLoading: false,
  contactCreateError: null,
  contactCreateSuccess: true,
});

const createContactFail = (
  state: ContactStateType,
  action: ContactActionType,
): ContactStateType => ({
  ...state,
  contactCreateLoading: false,
  contactCreateError: action.contactCreateError,
});

const reducer = (state = initialState, action: ContactActionType) => {
  switch (action.type) {
  case actionTypes.CREATE_CONTACT_START:
    return createContactStart(state);
  case actionTypes.CREATE_CONTACT_SUCCESS:
    return createContactSuccess(state);
  case actionTypes.CREATE_CONTACT_FAIL:
    return createContactFail(state, action);
  default:
    return state;
  }
};

export default reducer;
