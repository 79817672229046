import React, {useEffect, useState} from 'react';
import styles from './Navigation.module.scss';
import logoWithText from '../../assets/logo_with_text.svg';
import logoWithTextWhite from '../../assets/logo_with_text_white.svg';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {routes} from '../../config/Router/routes';
import cx from 'classnames';
import Button from '../Button/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import {StoreState} from '../../config/StoreProvider/StoreProvider';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import * as authService from '../../store/auth/service';
import {connect} from 'react-redux';
import {User} from '../../domain/User';
import {Language} from '../../domain/Translation';
import {useIntl} from 'react-intl';
import {translate} from '../../utility/messageTranslator/translate';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

type Props = {
  onLoginClick: () => void,
  onRegisterClick: () => void,
  user: null | User,
  onLogout: () => void,
  selectedLanguage: Language,
}

const MOBILE_BREAK_POINT = 1200;

export const Navigation = ({onLoginClick, onRegisterClick, user, onLogout, selectedLanguage}: Props) => {
  const intl = useIntl();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const {width} = useWindowSize();
  const navigate = useNavigate();
  const isHomepage = location.pathname === routes.homepage;

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const NAVIGATION_LINKS = [
    {
      route: routes.aboutUs,
      label: translate(intl, 'NAVIGATION.ABOUT_US', ''),
    },
    {
      route: routes.business,
      label: translate(intl, 'NAVIGATION.BUSINESS', ''),
    },
    {
      route: routes.faq,
      label: translate(intl, 'NAVIGATION.FAQ', ''),
    },
    {
      route: routes.contact,
      label: translate(intl, 'NAVIGATION.CONTACTS', ''),
    },
    {
      route: routes.europeanDonation,
      label: translate(intl, 'NAVIGATION.EUROPEAN_UNION_PROJECTS', ''),
    },
  ].filter((navigationLink) => selectedLanguage === 'lt' || navigationLink.route !== routes.europeanDonation);

  const handleLogoutClick = () => {
    onLogout();
    navigate(routes.homepage);
  };

  return (
    <>
      <div className={cx(styles.navigationContainer, {[styles.isDrawerOpen]: isMobileMenuOpen})}>
        <nav className={styles.navigation}>
          <div className={styles.logoSection}>
            <NavLink to="/">
              <img src={isHomepage && !isMobileMenuOpen ? logoWithTextWhite : logoWithText} alt="Packsender" />
            </NavLink>
          </div>
          <div className={styles.linksSection}>
            {width && width > MOBILE_BREAK_POINT && NAVIGATION_LINKS.map((navigationLink) => (
              <NavLink
                key={navigationLink.route}
                to={navigationLink.route}
                className={cx({
                  [styles.isHomepage]: isHomepage && !isMobileMenuOpen,
                })}
              >
                {navigationLink.label}
              </NavLink>
            ))}
            {width && width > MOBILE_BREAK_POINT && (
              <div className={cx(styles.buttonGroup, {[styles.isHomepage]: isHomepage && !isMobileMenuOpen})}>
                {!!user ? (
                  <>
                    <Button
                      onClick={() => navigate(routes.basket)}
                      isSecondary
                      isWhite={isHomepage && !isMobileMenuOpen}
                    >
                      {translate(intl, 'NAVIGATION.MY_ACCOUNT', '')}
                    </Button>
                    <Button onClick={handleLogoutClick}>
                      {translate(intl, 'NAVIGATION.LOGOUT', '')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={onLoginClick} isSecondary isWhite={isHomepage && !isMobileMenuOpen}>
                      {translate(intl, 'NAVIGATION.LOGIN', '')}
                    </Button>
                    <Button onClick={onRegisterClick}>
                      {translate(intl, 'NAVIGATION.REGISTER', '')}
                    </Button>
                  </>
                )}
              </div>
            )}
            <LanguageSwitcher isHomepage={isHomepage}/>
            {width && width <= MOBILE_BREAK_POINT && (
              <div className={cx(styles.burgerIcon, {[styles.isHomepage]: isHomepage && !isMobileMenuOpen})}>
                <FontAwesomeIcon
                  icon={isMobileMenuOpen ? faXmark : faBars}
                  size="lg"
                  onClick={() => setIsMobileMenuOpen((prev) => !prev)}
                />
              </div>
            )}
          </div>
        </nav>
      </div>
      {isMobileMenuOpen && width && width <= MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {NAVIGATION_LINKS.map((navigationLink) => (
            <NavLink
              key={navigationLink.route}
              to={navigationLink.route}
            >
              {navigationLink.label}
            </NavLink>
          ))}
          {!!user ? (
            <>
              <Button
                onClick={() => navigate(routes.basket)}
                isSecondary
                isWhite={isHomepage && !isMobileMenuOpen}
              >
                {translate(intl, 'NAVIGATION.MY_ACCOUNT', '')}
              </Button>
              <Button onClick={handleLogoutClick}>
                {translate(intl, 'NAVIGATION.LOGOUT', '')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onLoginClick} isSecondary isWhite={isHomepage && !isMobileMenuOpen}>
                {translate(intl, 'NAVIGATION.LOGIN', '')}
              </Button>
              <Button onClick={onRegisterClick}>
                {translate(intl, 'NAVIGATION.REGISTER', '')}
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.auth.user,
  selectedLanguage: state.auth.selectedLanguage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
