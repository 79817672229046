export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const SET_IS_LOGIN_VISIBLE = 'SET_IS_LOGIN_VISIBLE';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

export const LOGOUT = 'LOGOUT';

export type AuthActionTypes =
  typeof LOGIN_START |
  typeof LOGIN_SUCCESS |
  typeof LOGIN_FAIL |
  typeof REGISTER_START |
  typeof REGISTER_SUCCESS |
  typeof REGISTER_FAIL |
  typeof GET_USER_START |
  typeof GET_USER_SUCCESS |
  typeof GET_USER_FAIL |
  typeof SET_IS_LOGIN_VISIBLE |
  typeof SELECT_LANGUAGE |
  typeof LOGOUT
;
