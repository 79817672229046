import * as actionTypes from './actionTypes';
import {UserActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Profile} from '../../domain/User';
import {CartItem, ShipmentPrice} from '../../domain/Order';

export type UserStateType = {
  profile: null | Profile,
  getProfileError: HttpError,
  getProfileLoading: boolean,
  profileUpdateLoading: boolean,
  profileUpdateError: HttpError,
  profileUpdateSuccess: boolean,
  cartItems: CartItem[],
  selectedCartItem: CartItem | null,
  selectedShipmentPrice: ShipmentPrice | null,
};

export type UserActionType = UserStateType & {
  type: UserActionTypes,
};

export const initialState: UserStateType = {
  profile: null,
  getProfileError: null,
  getProfileLoading: false,
  profileUpdateLoading: false,
  profileUpdateError: null,
  profileUpdateSuccess: false,
  cartItems: [],
  selectedCartItem: null,
  selectedShipmentPrice: null,
};

const getProfileStart = (state: UserStateType): UserStateType => ({
  ...state,
  getProfileLoading: true,
  profileUpdateSuccess: false,
});

const getProfileSuccess = (state: UserStateType, action: UserActionType): UserStateType => ({
  ...state,
  getProfileLoading: false,
  getProfileError: null,
  profile: action.profile,
});

const getProfileFail = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  getProfileError: action.getProfileError,
  getProfileLoading: false,
});

const updateProfileStart = (state: UserStateType): UserStateType => ({
  ...state,
  profileUpdateLoading: true,
  profileUpdateSuccess: false,
});

const updateProfileSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  profileUpdateLoading: false,
  profileUpdateError: null,
  profileUpdateSuccess: true,
});

const updateProfileFail = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  profileUpdateError: action.profileUpdateError,
  profileUpdateLoading: false,
});

const addToCart = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  cartItems: [...state.cartItems, ...action.cartItems],
  selectedCartItem: action.cartItems[0],
});

const removeFromCart = (state: UserStateType): UserStateType => ({
  ...state,
  cartItems: [],
});

const selectCartItem = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  selectedCartItem: action.selectedCartItem,
});

const selectShipmentPrice = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  selectedShipmentPrice: action.selectedShipmentPrice,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
  case actionTypes.GET_PROFILE_START:
    return getProfileStart(state);
  case actionTypes.GET_PROFILE_SUCCESS:
    return getProfileSuccess(state, action);
  case actionTypes.GET_PROFILE_FAIL:
    return getProfileFail(state, action);
  case actionTypes.UPDATE_PROFILE_START:
    return updateProfileStart(state);
  case actionTypes.UPDATE_PROFILE_SUCCESS:
    return updateProfileSuccess(state);
  case actionTypes.UPDATE_PROFILE_FAIL:
    return updateProfileFail(state, action);
  case actionTypes.ADD_TO_CART:
    return addToCart(state, action);
  case actionTypes.REMOVE_FROM_CART:
    return removeFromCart(state);
  case actionTypes.SELECT_CART_ITEM:
    return selectCartItem(state, action);
  case actionTypes.SELECT_SHIPMENT_PRICE:
    return selectShipmentPrice(state, action);
  default:
    return state;
  }
};

export default reducer;
