import * as actionTypes from './actionTypes';
import {ContentActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Content} from '../../domain/Content';

export type ContentStateType = {
  contents: Content[],
  getContentsError: HttpError,
  getContentsLoading: boolean,
};

export type ContentActionType = ContentStateType & {
  type: ContentActionTypes,
};

export const initialState: ContentStateType = {
  contents: [],
  getContentsError: null,
  getContentsLoading: false,
};

const getContentsStart = (state: ContentStateType): ContentStateType => ({
  ...state,
  getContentsLoading: true,
});

const getContentsSuccess = (state: ContentStateType, action: ContentActionType): ContentStateType => ({
  ...state,
  getContentsLoading: false,
  getContentsError: null,
  contents: action.contents,
});

const getContentsFail = (
  state: ContentStateType, action: ContentActionType,
): ContentStateType => ({
  ...state,
  getContentsError: action.getContentsError,
  getContentsLoading: false,
});

const reducer = (state = initialState, action: ContentActionType) => {
  switch (action.type) {
  case actionTypes.GET_CONTENTS_START:
    return getContentsStart(state);
  case actionTypes.GET_CONTENTS_SUCCESS:
    return getContentsSuccess(state, action);
  case actionTypes.GET_CONTENTS_FAIL:
    return getContentsFail(state, action);
  default:
    return state;
  }
};

export default reducer;
