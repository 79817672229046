export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SELECT_CART_ITEM = 'SELECT_CART_ITEM';
export const SELECT_SHIPMENT_PRICE = 'SELECT_SHIPMENT_PRICE';

export type UserActionTypes =
  typeof GET_PROFILE_START |
  typeof GET_PROFILE_SUCCESS |
  typeof GET_PROFILE_FAIL |
  typeof UPDATE_PROFILE_START |
  typeof UPDATE_PROFILE_SUCCESS |
  typeof UPDATE_PROFILE_FAIL |
  typeof ADD_TO_CART |
  typeof REMOVE_FROM_CART |
  typeof SELECT_CART_ITEM |
  typeof SELECT_SHIPMENT_PRICE
;
