import * as actionTypes from './actionTypes';
import {FaqActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {FaqCategory, FaqQuestion} from '../../domain/Faq';

export type FaqStateType = {
  faqCategories: FaqCategory[],
  getFaqCategoriesError: HttpError,
  getFaqCategoriesLoading: boolean,
  faqQuestions: FaqQuestion[],
  getFaqQuestionsError: HttpError,
  getFaqQuestionsLoading: boolean,
};

export type FaqActionType = FaqStateType & {
  type: FaqActionTypes,
};

export const initialState: FaqStateType = {
  faqCategories: [],
  getFaqCategoriesError: null,
  getFaqCategoriesLoading: false,
  faqQuestions: [],
  getFaqQuestionsError: null,
  getFaqQuestionsLoading: false,
};

const getFaqCategoriesStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  getFaqCategoriesLoading: true,
});

const getFaqCategoriesSuccess = (state: FaqStateType, action: FaqActionType): FaqStateType => ({
  ...state,
  getFaqCategoriesLoading: false,
  getFaqCategoriesError: null,
  faqCategories: action.faqCategories,
});

const getFaqCategoriesFail = (
  state: FaqStateType, action: FaqActionType,
): FaqStateType => ({
  ...state,
  getFaqCategoriesError: action.getFaqCategoriesError,
  getFaqCategoriesLoading: false,
});

const getFaqQuestionsStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  getFaqQuestionsLoading: true,
});

const getFaqQuestionsSuccess = (state: FaqStateType, action: FaqActionType): FaqStateType => ({
  ...state,
  getFaqQuestionsLoading: false,
  getFaqQuestionsError: null,
  faqQuestions: action.faqQuestions,
});

const getFaqQuestionsFail = (
  state: FaqStateType, action: FaqActionType,
): FaqStateType => ({
  ...state,
  getFaqQuestionsError: action.getFaqQuestionsError,
  getFaqQuestionsLoading: false,
});

const reducer = (state = initialState, action: FaqActionType) => {
  switch (action.type) {
  case actionTypes.GET_FAQ_CATEGORIES_START:
    return getFaqCategoriesStart(state);
  case actionTypes.GET_FAQ_CATEGORIES_SUCCESS:
    return getFaqCategoriesSuccess(state, action);
  case actionTypes.GET_FAQ_CATEGORIES_FAIL:
    return getFaqCategoriesFail(state, action);
  case actionTypes.GET_FAQ_QUESTIONS_START:
    return getFaqQuestionsStart(state);
  case actionTypes.GET_FAQ_QUESTIONS_SUCCESS:
    return getFaqQuestionsSuccess(state, action);
  case actionTypes.GET_FAQ_QUESTIONS_FAIL:
    return getFaqQuestionsFail(state, action);
  default:
    return state;
  }
};

export default reducer;
