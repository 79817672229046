export const GET_FAQ_CATEGORIES_START = 'GET_FAQ_CATEGORIES_START';
export const GET_FAQ_CATEGORIES_SUCCESS = 'GET_FAQ_CATEGORIES_SUCCESS';
export const GET_FAQ_CATEGORIES_FAIL = 'GET_FAQ_CATEGORIES_FAIL';

export const GET_FAQ_QUESTIONS_START = 'GET_FAQ_QUESTIONS_START';
export const GET_FAQ_QUESTIONS_SUCCESS = 'GET_FAQ_QUESTIONS_SUCCESS';
export const GET_FAQ_QUESTIONS_FAIL = 'GET_FAQ_QUESTIONS_FAIL';

export type FaqActionTypes =
  typeof GET_FAQ_CATEGORIES_START |
  typeof GET_FAQ_CATEGORIES_SUCCESS |
  typeof GET_FAQ_CATEGORIES_FAIL |
  typeof GET_FAQ_QUESTIONS_START |
  typeof GET_FAQ_QUESTIONS_SUCCESS |
  typeof GET_FAQ_QUESTIONS_FAIL
;
